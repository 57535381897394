export class ServiceCategoryDTO {
  id: number;
  type: string;
  label: string;

  constructor(json: ServiceCategoryDTO) {
    this.id = json.id;
    this.type = json.type;
    this.label = json.label;
  }
}
