export const esBRStrings = {
    login: "LOGIN",
    logout: "Log out",
    bots: "Bots",
    cancel: "Cancel",
    dashboard: "Dashboard",
    botId: "Bot ID",
    botIdFilter: "ID filter",
    botName: "Bot name",
    botNameFilter: "Bot name filter",
    botLastLog: "Last log",
    botLastLogFilter: "Last log filter",
    botLastActionDate: "Last action date",
    dateModified: "Date modified",
    dateModifiedFilter: "Date modified filter",
    dateCreated: "Date created",
    deleteProxyButton: "Delete Proxy",
    deleteRegionButton: "Delete Region",
    deleteProxyDialog: "DELETE PROXY?",
    deleteRegionDialog: "DELETE REGION?",
    deleteProxy: "Are you sure you want to delete this proxy?",
    deleteRegion: "Are you sure you want to delete this region?",
    email: "Email",
    enterEmailLabel: "Enter email",
    english: "Eng",
    editProxyButton: "Edit proxy",
    editRegionButton: "Edit region",
    hasAuth: "Has auth",
    labelProxyName: "Proxy name",
    labelProxyURL: "Proxy URL",
    lastActionDateFilter: "Last action date filter",
    loops: "Loops",
    loopsRequired: "The loops field is required",
    loopsInvalid: "The number of loops must be at least 1",
    proxyName: "Name",
    password: "Password",
    portuguese: "Por",
    proxies: "Proxies",
    proxyGroups: "Proxy groups",
    region: "Region",
    regions: "Regions",
    spanish: "Esp",
    sessions: "Bot scenarios",
    botScenariosAndSessions: "Bot scenarios & sessions",
    username: "Username",
    accountGroups: "Accounts & groups",
    accountGroupsTitle: "Account groups",
    streamingBot: "Streaming bot",
    url: "URL",
    sessionInstances: "Executed bot sessions",
    name: "Name",
    service: "Service",
    key: "Key",
    keyFilter: "Key filter",
    labelFilter: "Label filter",
    countryCommonNameFilter: "Country filter",
    active: "Active",
    accountGroup: "Account group",
    deleteSelected: "Delete selected",
    deleteSelectedAccounts: "Delete selected accounts",
    switchGroupShort: "Switch Group",
    switchAccounts: "Switch Accounts",
    switchGroup: "Switch group of selected accounts",
    transferAccounts: "Transfer all accounts in group",
    transferAccountsH: "Transfer all accounts in a different group",
    targetAccountGroup: "Target account group",
    proxyGroup: "Proxy group",
    transferAccountsShort: "Transfer Accounts",
    cookieCollectionUploadedSuccessfully: "Cookie collection uploaded succesfully",
    schedule: "Schedule",
    accounts: "Accounts",
    groups: "Groups",
    useProxy: "Use proxy",
    addSession: "Add scenario",
    addProxy: "Add proxy",
    addRegion: "Add region",
    proxyDeletedSuccessfully: "Proxy deleted successfully.",
    regionDeletedSuccessfully: "Region deleted successfully.",
    label: "Label",
    addAccGroupField: "Add field for new account",
    parentAccount: "Parent account",
    ageGroup: "Age group",
    action: "Action",
    initialized: "Initialized",
    notInitialized: "Not initialized",
    null: "Null",
    sourceKey: "Source key",
    minDuration: "Min duration (in seconds)",
    maxDuration: "Max duration (in seconds)",
    back: "Back",
    sessionAddedSuccessfully: "Scenario added successfully",
    sessionEditedSuccessfully: "Scenario edited successfully",
    confirm: "Confirm",
    reset: "Reset",
    resetPassword: "Reset Password",
    passwordsDontMatch: "The passwords do not match.",
    passwordResetSuccess: "The password was reset successfully. Click on the button below to head back to the login page.",
    resetPasswordLink: "Please enter the email address of your account. You will receive an email with a link for resetting your password.",
    resetPasswordLinkGenerated: "Password reset link generated successfully. Please check your email.",
    resetPasswordNoCode: "Didn't receive code? Click here to re-send code.",
    resendCode: "Resend Code",
    administration: "Administration",
    users: "Users",
    firstName: "First name",
    lastName: "Last name",
    phoneNumber: "Phone number",
    roles: "Roles",
    enabled: "Enabled",
    delete: "Delete",
    edit: "Edit",
    addAccount: "Add account",
    accountDeletedSuccessfully: "Account Deleted Successfully",
    deleteAccountDialog: "Delete account ",
    deleteAccount: "Are you sure you want to delete this account?",
    deleteAccountButton: "Delete",
    accountAddedSuccessfully: "Account added successfully",
    accountEditedSuccessfully: "Account edited successfully",
    proxyAddedSuccessfully: "Proxy added successfully.",
    addUser: "Add user",
    successfullyAddedNewUser: "Successfully added new user",
    userUpdatedSuccessfully: "User edited successfully",
    editUser: "Edit user",
    save: "Save",
    fieldRequired: "The field is required",
    minMax: "The minimum value cannot be greater than the maximum value",
    sessionAddedUnsuccessfully: "The session cannot be added",
    saveChanges: "Save changes",
    actions: "Session actions",
    sessionEditedUnsuccessfully: "The session cannot be edited",
    editSession: "Edit scenario",
    proxyUpdatedSuccessfully: "Proxy updated successfully.",
    editAccount: "Edit account",
    sessionId: "Scenario ID",
    sessionName: "Scenario name",
    sessionKey: "Scenario key",
    completed: "Completed",
    filter: "Filter",
    preferredProxies: "Preferred proxies for regions",
    deleteUserButton: "Delete user",
    deleteUser: "Delete user",
    deleteUserDialog: "Are you sure you want to delete this user?",
    successfullyDeletedUser: "Successfully deleted!",
    successfullySwitchedAccounts: "Successfully switched accounts!",
    successfullyDeletedAccounts: "Successfully deleted accounts!",
    successfullyTransferedAccounts: "Successfully transfered accounts!",
    chooseFile: "Choose file",
    cookieCollection: "Cookie collection",
    exportCookieCollectionButton: "Export collection",
    upload: "upload",
    behavior: "Behavior",
    status: "Status",
    statusActive: "Active",
    statusIdle: "Idle",
    statusDied: "Died",
    emailConfirmation: "Email address confirmed",
    confirmationText: "Your email has already been confirmed.You can now login to the page!",
    confirmationError: "Your email address could not be verified!",
    error: "Error",
    passwordNew: "New password",
    passwordRetype: "Retype password",
    ok: "OK",
    success: "Success",
    passwordUpdatedSuccessfully: "User password successfully updated.",
    accountWorksProperly: "The account works properly",
    accountDoesNotWorkProperly: "The account does not work properly",
    AccountAddedUnsuccessfully: "Account added unsuccessfully",
    AccountEditedUnsuccessfully: "Account edited unsuccessfully",
    AccountAddedSuccessfully: "Account added successfully",
    RegionAddedSuccessfully: "Region added successfully",
    RegionUpdatedSuccessfully: "Region updated successfully",
    version: "Version",
    maxSessionDurationInSeconds: "Max session duration (in seconds)",
    maxSessionDurationInSecondsExceededMessage: "The max duration of a session cannot exceed 86400 seconds (24 hours)",
    latest: "Latest",
    session: "Bot scenario",
    account: "Account",
    proxy: "Proxy",
    massiveProxyEdit: "Proxies massive edit",
    enableEdit: "Enable edit",
    saveAll: "Save all",
    bot: "Bot",
    durationInSeconds: "Duration in seconds",
    numberOfListenedTracks: "Number of listened tracks",
    timeElapsedInSeconds: "Time elapsed in seconds",
    errorMessage: "Error Message",
    dateFinished: "Date finished",
    finished: "Finished",
    listenedTracks: "Listened tracks",
    startDate: "Start date",
    paymentAccountType: "payment account type",
    addPaymentAccount: "Add payment account",
    noProxy: "No proxy",
    noBot: "No bot",
    order: "Order",
    artist: "Artist",
    sessionInstanceDetails: "Session instance details",
    addAccountGroup: "Add account group",
    editAccountGroup: "Edit account group",
    userAlreadyAdded: "User already added",
    existingAccounts: "Existing accounts",
    addExistingAccounts: "Add existing accounts",
    userIncompatibleService: "The service of this account does not equal to the chosen account group service.",
    Clear: "Clear",
    accountGroupAddedSuccessfully: "Account group added successfully",
    accountGroupUpdatedSuccessfully: "Account group updated successfully",
    days: "days",
    hours: "hours",
    hour: "Hour",
    minutes: "minutes",
    seconds: "seconds",
    botDetails: "Bot details",
    paymentAccountAddedSuccessfully: "Payment account added successfully",
    paymentAccountAddedUnsuccessfully: "Payment account added unsuccessfully",
    paymentAccountEditedUnsuccessfully: "Payment account edited unsuccessfully",
    paymentAccountEditedSuccessfully: "Payment account edited successfully",
    editPaymentAccount: "Edit payment account",
    paymentAccounts: "Payment Accounts",
    listenedTracksDetails: "Listened tracks details",
    trackTimeElapsed: "Track time elapsed",
    accountDetails: "Account details",
    startTime: "Start time",
    accountGroupScheduleTable: "Schedules",
    editedSuccessfully: "Edited successfully",
    deleteSchedule: "Delete schedule",
    deleteThisSchedule: "Do you want to delete this schedule",
    accountGroupSchedule: "Account group schedule",
    newAccounts: "New accounts",
    accountsInGroup: "Accounts in group",
    successfullyAdded: "Successfully added",
    editSchedule: "Edit schedule",
    accountStatus: "Account Status",
    actionNeeded: "The last bot session of the account has not been fully successful.",
    pleaseCheck: " Please check if:",
    accountIsBanned: "The account is not banned / blocked",
    accountHasValidCookies: " The account has valid cookies",
    needAction: "The account needs actions. Click for more info.",
    weight: "Weight",
    client: "Client",
    nameFilter: "Name filter",
    editClient: "Edit client",
    urlFilter: "URL filter",
    trackName: "Track name",
    totalExecutedBotSessions: "Total executed bot sessions: ",
    totalUpTime: "Total up time: ",
    listening: "listening",
    pausing: "pausing",
    clientId: "Client ID",
    clients: "Clients",
    clientName: "Client name",
    addClient: "Add client",
    deleteClient: "Delete client",
    deleteClientModal: "DELETE CLIENT?",
    clientNameFilter: "Client Name Filter",
    clientDeletedSuccessfully: "Client deleted successfully.",
    clientUpdatedSuccessfully: "Client updated successfully.",
    clientAddedSuccessfully: "Client added successfully.",
    sharedActions: 'Shared actions by service and region',
    sharedActionId: "Shared action id",
    addSharedAction: "Add shared action",
    editSharedAction: "Edit shared action",
    deleteSharedAction: 'Delete shared action?',
    sharedActionAddedSuccessfully: "Shared action added successfully",
    sharedActionEditedSuccessfully: "Shared action edited successfully",
    deleteThisSharedAction: "Do you want to delete this action ?",
    sessionInstanceConditionType: "Condition",
    conditionActive: "Active",
    conditionStalled: "Stalled",
    conditionFinished: "Finished",
    conditionEmpty: "Empty",
    conditionFinishedSuccessfully: "Successfully finished",
    conditionFinishedUnsuccessfully: "Unsuccessfully finished",
    accountGroupDeletedSuccessfully: "Account group deleted successfully.",
    deleteAccountGroup: "Delete account group",
    deleteAccountGroupConsent: "Are you sure you want to delete the account group?",
    totalScenarios: "Total executed sessions",
    running: "Running",
    idle: "Idle",
    dead: "Dead",
    runningExplanation: "Running: The bot is actively playing a scenario",
    idleExplanation: "Idle: The bot is waiting for a scheduled scenario to start playing",
    deadExplanation: "Dead: The bot is disconnected from the system and has probably died",
    totalBots: "Total bots",
    amount: "Amount",
    changeServiceMsg: "In order to change the service, please remove the currently added accounts",
    stopSessions: "Stop running sessions",
    stopSessionsConfirmation: "Are you sure you want to stop all running bot sessions?",
    sessionsStoppedSuccessfully: "Session stopping initiated successfully. It could take a couple of minutes until all running sessions are stopped.",
    emptyTableMessage: "Empty table. Select accounts",
    randomizeFromSharedActions: "Randomize from shared actions",
    stopSessionInstance: "Stop session",
    stopSessionInstanceConfirmation: "Are you sure you want to stop the running session?",
    sessionInstanceStoppedSuccessfully: "Session stopping initiated successfully. It could take a couple of minutes until the session is stopped.",
    regionEqualAsProxyRegion: "Region must be equal as proxy region",
    executionTotalUpTime: "Execution total up time",
    successfullyExecuted: "Successfully executed",
    totalUpTimeOfExecution: "Total session duration",
    profile: "Profile",
    warningMessageProfile: "No account profile was detected during this session. It could be due to: ",
    firstWarningProfile: "1. The cookies being outdated",
    secondWaringProfile: "2. The account being blocked",
    totalBotUpTime: "Total bot up time",
    downloadReport: "Download report",
    reports: "Reports",
    accountManagement: "Account management",
    clientDashboard: "Client dashboard",
    clientReports: "Client accountManagement",
    geographicReport: "Geographic report",
    statisticReport: "Statistic report",
    accountUpdatedSuccessfully: "Account updated successfully.",
    availableAccounts: "Available accounts",
    s: "s",
    m: "m",
    h: "h",
    viewsCount: "Views count",
    timeOfListening: "Time of listening",
    listenedTrackStatistics: "Listened track statistics",
    report: "Report",
    getReportForProxy: "Get report for proxy ",
    dateFrom: "Date from",
    dateTo: "Date to",
    pleaseEnterValidDates: "Please enter valid dates",
    geoViewsCountReport: "Geo views count report",
    total: "Total",
    geoTimeElapsedReport: "Geo time elapsed report",
    accountGroupName: "Account group",
    numberOfSchedules: "Number of schedules",
    numberOfAccounts: "Number of accounts",
    scheduledIntervalNoScenarios: "The selected interval does not have scheduled scenarios",
    date: "Date",
    streams: "Streams",
    trackNameFilter: "Track name filter",
    artistNameFilter: "Artist name filter",
    totalViewsExternal: "Total views (actual)",
    totalViewsInternal: "Total views (bot)",
    views: "Views",
    viewsBot: "Views (bot)",
    viewsActual: "Views (actual)",
    comparingViewsTab: "Comparing views tab",
    comparingViews: "Comparing views",
    referenceKey: "Reference key",
    id: "Id",
    scraperTargets: "Scraper targets",
    premium: "Premium",
    noProfile: "No user profile was detected while running the bot. Please update the account cookies.",
    loginUsername: "Login username",
    loginPassword: "Login password",
    workingCookies: "Working cookies",
    sessionDetailsLink: "Session details link",
    problematicAccounts: "Problematic accounts",
    lastSessionInstance: "Last executed session",
    lastFailedSessionInstance: "Last failed session",
    lastSessionInstanceWithoutProfile: "Last session without profile",
    lastSessionInstanceWithProfile: "Last session with profile",
    lastSuccessfulSessionInstance: "Last successful session",
    performedLogin: "Performed login",
    usedCookies: "Used cookies",
    thereIsNoSuchSessionForThisAccount: "There is no such session for this account",
    downloadProxyListeningStatsReport: "Download proxy listening stats report",
    downloadProxyAccountsReport: "Download proxy accounts report",
    botPlatform: "Bot platform",
    screenshots: "Screenshots",
    downloadAllScreenshots: "Download all screenshots",
    downloadScreenshots: "Download screenshots",
    importAccounts: "Import accounts",
    accountsImportedSuccessfully: "Accounts imported successfully",
    created: "Created",
    importAccountsForCreation: "Import accounts for creation",
    creationProxy: "Proxy for account creation",
    accountGroupAfterCreation: "Account group after creation",
    downloadAccountsPerProxyReport: "Download accounts per proxy report",
    switchAccountGroupOnFinish: "Switch account group of account on finish",
    sameAxis: "Same axis",
    uniqueListenersBot: "Unique listeners (bot)",
    uniqueListenersActual: "Unique listeners (actual)",
    uniqueListeners: "Unique listeners",
    phones: "Mobile phones",
    serialNumber: "Serial number",
    friendlyName: "Friendly name",
    mobilePhone: "Mobile phone",
    server: "Server",
    statusDate: "Status Date (date modified)",
    serialNumberFilter: "Serial number filter",
    friendlyNumberFilter: "Friendly number filter",
    serverFilter: "Server filter",
    statusDateFilter: "Status date",
    statusFilter: "Status filter",
    mobilePhoneUpdatedSuccessfully: "Mobile phone updated successfully",
    mobilePhoneDetails: "Mobile phone details",
    mobileProxies: "Mobile proxies",
    country: "Country",
    proxyUrl: "Proxy Url",
    rotateUrl: "Rotate Url",
    minTrackDurationInSeconds: "Min track duration (in seconds)",
    maxTrackDurationInSeconds: "Max track duration (in seconds)",
    connectedAccounts: "Connected accounts",
    perRegionScheduling: "Per-region scheduling",
    perServiceScheduling: "Per-service scheduling",
    disabled: "Disabled",
    addToFavorites: "Save to favourites",
    followArtist: "Follow artist",
    recoveryEmail: "Recovery email",
    reservePoolProxyGroup: "Reserve pool proxy group",
    shouldReboot: "Should reboot",
    importProxies: "Import proxies",
    import: "Import",
    namePrefix: "Name prefix",
    proxiesImportedSuccessfully: "Proxies imported successfully",
    totalNumberOfAccounts: "TOTAL NUMBER OF ACCOUNTS: ",
    totalNumberOfProxies: "TOTAL NUMBER OF PROXIES: ",
    totalNumberOfRegisteredBots: "TOTAL NUMBER OF REGISTERED BOTS: ",
    totalNumberOfSuccessfullyExecutedSessions: "TOTAL NUMBER OF SUCCESSFULLY EXECUTED SESSIONS: ",
    totalStreamsOnSelectedDate: "TOTAL STREAMS ON SELECTED DATE: ",
    accountsThatSwitchedAccountGroup: "Accounts that switched account group",
    accountsThatHaveAtLeastOneListenedTrack: "Accounts that have at least one listened track",
    accountsWithlastSuccessfulSession: "Accounts with last successful session",
    accountsWithNoListenedTracks: "Accounts with no listened tracks",
    totalExecutedSessionInstances: "Total executed session instances",
    selectedAccounts: "Selected accounts: ",
    initialProxy: "Initial proxy",
    usedProxy: "Used proxy",
    maxAccountsPerProxy: "Max accounts per proxy",
    maxTransferredAccounts: "Max transferred accounts",
    addProxyGroup: "Add proxy group",
    editProxyGroup: "Edit proxy group",
    deleteProxyGroup: "Delete proxy group",
    deleteProxyGroupConfirm: "Are you sure you want to delete this proxy group?",
    proxyGroupCreated: "Proxy group created successfully!",
    proxyGroupUpdated: "Proxy group updated successfully!",
    proxyGroupDeleted: "Proxy group deleted successfully!",
    proxyGroupName: "Proxy group name",
    availableProxies: "Available proxies",
    proxiesInGroup: "Proxies in group",
    numberOfListenedSongs: "Listened songs",
    queue: "Queue",
    queues: "Queues",
    addQueue: "Add queue",
    addQueues: "Add Queues",
    editQueueButton: "Edit queue",
    labelQueueKey: "Queue key",
    queueAddedSuccessfully: "Queue added successfully.",
    queueUpdatedSuccessfully: "Queue updated successfully.",
    queueDeletedSuccessfully: "Queue deleted successfully",
    deleteQueueButton: "Delete Queue",
    deleteQueueDialog: "DELETE QUEUE?",
    deleteQueue: "Are you sure you want to delete this queue?",
    listenedTracksPerArtist: "Listened tracks per artist",
    totalListenedTracksPerHour: "Total listened tracks by hour",
    maxPlaylistDurationInSeconds: 'Max playlist duration in seconds',
    playlistGenerationProbability: 'Playlist generation probability',
    genreKeys: "Genre keys",
    probabilityBetweenZeroAndOne: "The probability should have value between 0 and 1",
    search: "Search",
    playlists: "Playlists",
    addNewTargetPlaylist: "Add new target playlist",
    editTargetPlaylist: "Edit target playlist",
    playlistsForGeneration: " Playlists for generation",
    description: "Description",
    targetPlaylist: "Target playlist",
    addNew: "Add new",
    importTargetPlaylist: "Import target playlist",
    targetPlaylistUploadedSuccessfully: "Target playlist uploaded succesfully",
    title: "Title",
    successfullyAddedTargetPlaylist: "Target playlist is successfully added",
    successfullyEditedTargetPlaylist: "Target playlist is successfully edited",
    itemSuccess: "Target playlist item is added successfully",
    itemDeletedSuccessfully: "Target playlist item is deleted successfully",
    editTargetPlaylistItem: "Edit target playlist item",
    addNewTargetPlaylistItem: "Add new target playlist item",
    noDataAvailable: "No data available",
    problematicBots: "Problematic bots",
    isMobile: "Mobile",
    lastLog: "Last log",
    lastActionDate: "Last action date",
    condition: "Condition",
    fingerprints: "Fingerprints",
    uploadSuccessFingerprint: "Successfully uploaded fingerprint",
    fingerprintCollection: "Fingerprints collection",
    exportFingerprints: "Export fingerprints",
    addGenre: "Add genre",
    editGenre: "Edit genre",
    genreEditSuccessfully: "Genre edited successfully!",
    genreDeletedSuccessfully: "Genre deleted successfully!",
    genreAddedSuccessfully: "Genre added successfully!",
    genreKey: "Genre key",
    genreName: "Genre name",
    deleteGenre: "DELETE GENRE?",
    deleteGenreM: "Delete genre",
    deleteGenreSure: "Are you sure you want to delete this genre?",
    genres: "Genres",
    mainGenre: "Main genre",
    mainGenreProbability: "Main genre probability",
    select: "Select",
    relatedGenres: "Related genres",
    activationDate: "Activation date",
    recordLabels: "Record labels",
    addRecordLabel: "Add record label",
    editRecordLabel: "Edit record label",
    recordLabelAddedSuccessfully: "The record label has been added successfully!",
    recordLabelEditedSuccessfully: "The record label has been edited successfully!",
    recordLabelDeletedSuccessfully: "The record label has been deleted successfully!",
    recordLabelKey: "Record label key",
    recordLabelName: "Record label name",
    deleteRecordLabel: "DELETE RECORD LABEL?",
    deleteRecordLabelM: "Delete record label",
    deleteRecordLabelSure: "Are you sure you want to delete this record label?",
    recordLabel: "Record label",
    proxyToProxy: "Proxy-to-proxy",
    maxAccounts: "Max accounts",
    onlyAccountsWithNoListenedTracks: "Only accounts with no listened tracks",
    provider: "Provider",
    asn: "Autonomous system number",
    countryFilter: "Country filter",
    providerFilter: "Provider filter",
    asnFilter: "ASN filter",
    addProxyToProxy: "Add",
    editProxyToProxy: "Edit proxy to proxy",
    proxyToProxyAddedSuccessfully: "The proxy to proxy has been added successfully!",
    proxyToProxyEditedSuccessfully: "The proxy to proxy has been edited successfully!",
    proxyToProxyDeletedSuccessfully: "The proxy to proxy has been deleted successfully!",
    deleteProxyToProxy: "DELETE PROXY TO PROXY?",
    deleteProxyToProxyM: "Delete proxy to proxy",
    deleteProxyToProxySure: "Are you sure you want to delete this proxy to proxy?",
    proxyToProxyName: "Proxy to proxy name",
    type: "Type",
    playlistGeneration: "Playlist generation",
    searchAndPlay: "Search and play",
    diagnostics: "Diagnostics",
    accountsWithListenedTracks: "Accounts with listened tracks",
    accountsWithIncorrectPassword: "Accounts with incorrect password",
    accountsWithListenedTracksDayBeforeFirstPart: "Accounts without listened tracks in the date range, ",
    accountsWithListenedTracksDayBeforeSecondPart: "but with listened tracks the day before",
    exportAccountsWithoutListenedTracks: "Export accounts without listened tracks",
    exportAccountsWithIncorrectPassword: "Export accounts with incorrect password",
    exportAccountsWithListenedTracksDayBefore: "Export accounts with listened tracks the day before",
    addFiltersMessage: "Please select values in all the filters and click the filter button to show diagnostic data. The max selected date range is 7 days.",
    dateFromValidationAfter: "Date from cannot be later than date to",
    dateToValidationBefore: "Date to cannot be earlier than date from",
    dateFromValidationRange: "Date from cannot be more than 7 days from date to",
    dateToValidationRange: "Date to cannot be more than 7 days from date from",
    dateFromRequired: "Date from is required",
    dateToRequired: "Date to is required",
    serviceRequired: "Service is required",
    deleteTargetPlaylist: "Are you sure you want to delete this target playlist?",
    nonReversableAction: "This action cannot be reversed.",
    copyScenario: "Copy scenario",
    scenarioName: "Scenario name",
    copy: "Copy",
    addCopy: "Add copy",
    successCopy: "Successfully copied the scenarios",
    accountGroupRequired: "The account group is required",
    scenarioNameRequired: "The scenario name is required",
    activity: "Activity",
    scheduledAccounts: "Scheduled accounts:",
    likeProbability: "Like probability"
};
