import React from 'react';
import './App.css';
import {RouterProvider} from "react-router-dom";
import {createRouter} from "./router/Router";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Localization} from "./localization/Localization";
import {createTheme, ThemeProvider} from "@mui/material";
import "./css/global.css";
import ScrollTopButton from "./components/ScrollTopButton";
import ScrollBottomButton from "./components/ScrollBottomButton";
const theme = createTheme({
    palette:{
        primary:{
            main : "#505f4e"
        },
        secondary : {
            main : "#e4e4e2"
        }
    },
    typography:{
        fontFamily : 'Tahoma, sans-serif',
        fontSize : 13,
        fontWeightLight : 400,
        fontWeightRegular : 500,
        fontWeightMedium : 600,
        fontWeightBold : 700
    }
})

function App() {
    Localization.initialize();

    return (
        <ThemeProvider theme = {theme}>
            <div className="App">
                <RouterProvider router={createRouter()}/>
                <ScrollTopButton/>
                <ScrollBottomButton/>
            </div>
        </ThemeProvider>
    );
}

export default App;
