import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import '../css/ScrollBottomButton.css';
import { KeyboardArrowDown } from '@mui/icons-material';

const ScrollBottomButton: React.FC = () => {
    const [showScrollBottom, setShowScrollBottom] = useState(false);

    const checkScrollBottom = () => {
        if (!showScrollBottom && document.documentElement.scrollHeight > window.innerHeight && window.scrollY < document.documentElement.scrollHeight - window.innerHeight) {
            setShowScrollBottom(true);
        } else if (showScrollBottom && window.scrollY >= document.documentElement.scrollHeight - window.innerHeight) {
            setShowScrollBottom(false);
        }
    };

    const scrollToBottom = () => {
        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollBottom);
        return () => {
            window.removeEventListener('scroll', checkScrollBottom);
        };
    }, [showScrollBottom]);

    return (
        <CSSTransition
            in={showScrollBottom}
            timeout={1100}
            classNames="fade"
            unmountOnExit
            mountOnEnter
        >
            <div className="scroll-to-bottom">
                <button onClick={scrollToBottom} id="go-to-bottom">
                    <KeyboardArrowDown className="button-down" />
                </button>
            </div>
        </CSSTransition>
    );
};

export default ScrollBottomButton;
