import React, { useState, useEffect } from 'react';
import '../css/ScrollTopButton.css';
import {KeyboardArrowUp} from "@mui/icons-material";
import { CSSTransition } from 'react-transition-group';

const ScrollTopButton: React.FC = () => {
    const [showScrollTop, setShowScrollTop] = useState(false);

    const checkScrollTop = () => {
        if (!showScrollTop && window.scrollY > 200) {
            setShowScrollTop(true);
        } else if (showScrollTop && window.scrollY <= 200) {
            setShowScrollTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => {
            window.removeEventListener('scroll', checkScrollTop);
        };
    }, [showScrollTop]);

    return (
        <CSSTransition
            in={showScrollTop}
            timeout={1100}
            classNames="fade"
            unmountOnExit
            mountOnEnter
        >
            <div className="scroll-to-top">
                <button onClick={scrollToTop} id="return-to-top">
                    <KeyboardArrowUp className="button-top" />
                </button>
            </div>
        </CSSTransition>
    );
};

export default ScrollTopButton;
