import {ServiceCategoryDTO} from "./ServiceCategoryDTO";

export class ServiceDTO {
    id: number;
    type: string;
    label: string;
    serviceCategoryId: number;
    serviceCategory?: ServiceCategoryDTO;

    constructor(json: { id: number, type: string, label: string, serviceCategoryId: number, serviceCategory?: ServiceCategoryDTO }) {
        this.id = json.id;
        this.type = json.type;
        this.label = json.label;
        this.serviceCategoryId = json.serviceCategoryId;
        this.serviceCategory = json.serviceCategory ? new ServiceCategoryDTO(json.serviceCategory) : undefined;
    }
}
