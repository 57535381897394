import axios from "../network/AxiosInstance";
import { ActionType } from "../models/ActionType";
import { AuthService } from "./AuthService";

export class ActionTypeService{
    static async getAllActionTypes(serviceCategoryId?: number):Promise<Array<ActionType>>{
        let params = new URLSearchParams();

        if (serviceCategoryId !== undefined) {
            params.append("serviceCategoryId", serviceCategoryId.toString())
        }

        const response =  await axios.get<Array<ActionType>>(`${AuthService.PREFIX}/bot-session/action-type/all/`, {
            params: params,
        });

        const data = response.data;
        return data.map(actionType => new ActionType(actionType))
    }
}
