import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogTitle, SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { strings } from '../localization/Localization';

interface BoschDialogProps {
  open: boolean;
  nonReversableAction: boolean
  onClose: () => void;
  onConfirm: () => void;
  title: string,
  content?: React.ReactNode;
  fullScreen?: boolean;
  maxWidth?: false | Breakpoint;
  confirmActionText?: string;
}

export const BotsDialog = ({nonReversableAction, open, onClose, onConfirm, title, content, fullScreen, maxWidth, confirmActionText}: BoschDialogProps) => {

  const handleConfirmAction = () => {
    onConfirm();
  }

  return <Dialog open={open} fullScreen={fullScreen} maxWidth={maxWidth} fullWidth>
    <DialogTitle color='primary' className={'d-flex justify-content-between'}>
      {title}
      <IconButton type='button' onClick={onClose}>
        <SvgIcon component={CloseIcon} inheritViewBox/>
      </IconButton>
    </DialogTitle>
    <DialogContent dividers>
      {content}
    </DialogContent>
    <DialogActions>
      <Button variant={'contained'} onClick={onClose}>{strings.cancel}</Button>
      <Button variant={'contained'} color={nonReversableAction ? "error" : "primary"}  onClick={handleConfirmAction}>{confirmActionText ?? strings.confirm}</Button>
    </DialogActions>
  </Dialog>;
}
