import {SideBarPage} from "../../components/SideBarPage";
import {CenteredCardLayout} from "../../components/CenteredCardLayout";
import {strings} from "../../localization/Localization";
import React, {useEffect, useState} from "react";
import { Chart } from "react-google-charts";
import {BotConditionService} from "../../services/BotConditionService";
import {DashboardProxies} from "./DashboardProxies";
import {DashboardSessions} from "./DashboardSessions";
import {DashboardTotalData} from "./DashboardTotalData";
import {DashboardAccounts} from "./DashboardAccounts";
import {DashboardSessionInstances} from "./DashboardSessionInstances";
import {Grid} from "@mui/material";
import {DashboardBots} from "./DashboardBots";
import {SchedulingChart} from "./SchedulingChart";

export function Dashboard() {
    const [alive, setAlive] = useState<number | undefined>();
    const [idle, setIdle] = useState<number | undefined>();
    const [died, setDied] = useState<number | undefined>();

    const botConditionData = [
        ["Total bots", "Amount"],
        ["Running", alive],
        ["Idle", idle],
        ["Dead", died],
    ];

    const botsOptions = {
        title: "Total bots",
        pieSliceText: "label",
        fontSize: 14,
        colors: ["#109618", "#f90", "#dc3912"] //alive, idle, dead
    };

    function getBotCondition(){
        BotConditionService.botCondition().then((data) => {
            setAlive(data?.alive);
            setIdle(data?.idle);
            setDied(data?.died);
        })
    }

    useEffect(() => {
        getBotCondition();
    }, [alive, idle, died])

    return(
        <SideBarPage pageTitle={strings.dashboard} component={
            <>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid className={"container-fluid"} style={{ display: "flex",flexDirection: "row" }} columns={16}>
                        <Grid item xs={10} marginRight={5} style={{borderStyle: "solid", borderWidth: 2, borderRadius: 5, borderColor: "#e9ecef"}}>
                        <DashboardTotalData />
                        </Grid>
                        <Grid minHeight={"150px"} maxHeight={"240px"} maxWidth={"auto"} className={"align-content-end"} item xs={4}
                              style={{borderStyle: "solid", borderWidth: 2, borderRadius: 5, borderColor: "#e9ecef"}}>
                            <Chart
                                chartType="PieChart"
                                data={botConditionData}
                                options={botsOptions}
                                width={"100%"}
                                height={"230px"}
                            />
                        </Grid>
                    </Grid>
                    <Grid className={"container-fluid mt-4"} style={{ display: "flex",flexDirection: "row"}} columns={16}>
                            <Grid item xs={8} marginRight={5} style={{borderStyle: "solid", borderWidth: 2, borderRadius: 5, borderColor: "#e9ecef", minHeight: "390px"}}>
                                <DashboardBots/>
                            </Grid>

                            <Grid item xs={8} style={{borderStyle: "solid", borderWidth: 2, borderRadius: 5, borderColor: "#e9ecef", minHeight: "390px"}}>
                                <DashboardProxies />
                            </Grid>
                    </Grid>

                    <Grid className={"container-fluid mt-4"} style={{ display: "flex", flexDirection: "row"}} columns={16}>
                        <Grid item xs={8} marginRight={5} style={{borderStyle: "solid", borderWidth: 2, borderRadius: 5, borderColor: "#e9ecef", minHeight: "390px"}}>
                            <DashboardAccounts/>
                        </Grid>
                        <Grid item xs={8} style={{borderStyle: "solid", borderWidth: 2, borderRadius: 5, borderColor: "#e9ecef", minHeight: "393px"}}>
                            <DashboardSessions />
                        </Grid>
                    </Grid>

                    <Grid className={"container-fluid mt-4"} style={{ display: "flex",flexDirection: "row"}} columns={16}>
                        <Grid item xs={16} style={{borderStyle: "solid", borderWidth: 2, borderRadius: 5, borderColor: "#e9ecef", minHeight: "390px"}}>
                            <DashboardSessionInstances />
                        </Grid>


                    </Grid>

                    <Grid className={"container-fluid mt-4 tableBorderCSS"} style={{ display: "flex",flexDirection: "row"}}>
                        <CenteredCardLayout maxWidth={"100%"} minWidth={"100%"} minHeight={"250px"} maxHeight={"auto"}
                                            style={{borderStyle: "solid", borderWidth: 2, borderRadius: 5, borderColor: "#e9ecef"}}>
                            <SchedulingChart isDashboard={true} />
                        </CenteredCardLayout>
                    </Grid>
                </Grid>
            </>

        } />

    )
}
