import {strings} from "../../localization/Localization";
import {Routes} from "../../router/Routes";
import {SideBarPage} from "../../components/SideBarPage";
import {Box} from "@mui/system";
import React, {useEffect, useState} from "react";
import {Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {Clear} from "@mui/icons-material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {ServiceDTO} from "../../models/ServiceDTO";
import {ServicesService} from "../../services/ServicesService";
import dayjs, {Dayjs} from "dayjs";
import {AccountService} from "../../services/AccountService";
import {ChartComponent} from "../../components/ChartComponent";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {FullPageLoadingCircle} from "../../components/FullPageLoadingCircle";
import {FileDownloadUtils} from "../../utils/FileDownloadUtils";
import {Controller, useForm} from "react-hook-form";

type FormValues = {
    serviceId: number | undefined;
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
};

export function Diagnostics() {
    const [services, setServices] = useState<ServiceDTO[] | undefined>([]);
    const [percentageListenedTracks, setPercentageListenedTracks] = useState<number | null>(null);
    const [percentageIncorrectPassword, setPercentageIncorrectPassword] = useState<number | null>(null);
    const [percentageWithListenedTracksDayBefore, setPercentageWithListenedTracksDayBefore] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [shouldShowDiagnosticData, setShouldShowDiagnosticData] = useState(false);

    const {
        control,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues: {
            serviceId: undefined,
            dateFrom: null,
            dateTo: null,
        },
        mode: "onChange",
    });

    const serviceId = watch('serviceId');
    const dateFrom = watch('dateFrom');
    const dateTo = watch('dateTo');

    const listLinks = [
        {label: strings.dashboard, currentlyOpened: false, href: Routes.HOME},
        {label: strings.diagnostics, currentlyOpened: true},
    ]

    useEffect(() => {
        async function getAllServices(): Promise<void> {
            const services = await ServicesService.getAllServices();
            setServices(services);
        }

        getAllServices().then(_ => {});
    }, []);

    async function fetchPercentages(): Promise<void> {
        if (serviceId && dateFrom && dateTo) {
            setIsLoading(true);
            try {
                const [listenedTracks, incorrectPassword, withoutListenedTracksButListenedDayBefore] = await Promise.all([
                    AccountService.getPercentageAccountsWithListenedTracks(serviceId, DateTimeUtils.formatDateDayJs(dateFrom), DateTimeUtils.formatDateDayJs(dateTo)),
                    AccountService.getPercentageWithIncorrectPassword(serviceId, DateTimeUtils.formatDateDayJs(dateFrom), DateTimeUtils.formatDateDayJs(dateTo)),
                    AccountService.getPercentageAccountsWithListenedTracksDayBefore(serviceId, DateTimeUtils.formatDateDayJs(dateFrom), DateTimeUtils.formatDateDayJs(dateTo))
                ]);
                setPercentageListenedTracks(listenedTracks);
                setPercentageIncorrectPassword(incorrectPassword);
                setPercentageWithListenedTracksDayBefore(withoutListenedTracksButListenedDayBefore);
            } finally {
                setIsLoading(false);
            }
        }
    }

    const validateDateFrom = (date: Dayjs | null) => {
        if (!date) return strings.dateFromRequired;

        if (dateTo) {
            if (date.isAfter(dateTo)) return strings.dateFromValidationAfter;

            const maxDateFrom = dayjs(dateTo).subtract(7, "days");
            if (date.isBefore(maxDateFrom)) return strings.dateFromValidationRange;
        }

        return true;
    };

    const validateDateTo = (date: Dayjs | null) => {
        if (!date) return strings.dateToRequired;

        if (dateFrom) {
            if (date.isBefore(dateFrom)) return strings.dateToValidationBefore;

            const maxDateTo = dayjs(dateFrom).add(7, "days");
            if (date.isAfter(maxDateTo)) return strings.dateToValidationRange;
        }

        return true;
    };

    async function exportAccountsWithoutListenedTracks(): Promise<void> {
        if (serviceId && dateFrom && dateTo) {
            const result = await AccountService.exportAccountsWithoutListenedTracks(serviceId, DateTimeUtils.formatDateDayJs(dateFrom), DateTimeUtils.formatDateDayJs(dateTo));
            FileDownloadUtils.downloadFile(result);
        }
    }

    async function exportAccountsWithListenedTracksDayBefore(): Promise<void> {
        if (serviceId && dateFrom && dateTo) {
            const result = await AccountService.exportAccountsWithListenedTracksDayBefore(serviceId, DateTimeUtils.formatDateDayJs(dateFrom), DateTimeUtils.formatDateDayJs(dateTo));
            FileDownloadUtils.downloadFile(result);
        }
    }

    async function exportAccountsWithIncorrectPassword(): Promise<void> {
        if (serviceId && dateFrom && dateTo) {
            const result = await AccountService.exportAccountsWithIncorrectPassword(serviceId, DateTimeUtils.formatDateDayJs(dateFrom), DateTimeUtils.formatDateDayJs(dateTo));
            FileDownloadUtils.downloadFile(result);
        }
    }

    function onSubmit(): void {
        fetchPercentages();
        setShouldShowDiagnosticData(true);
    }

    return (
        <>
            <FullPageLoadingCircle loading={isLoading}/>
            <SideBarPage
                pageTitle={strings.diagnostics}
                breadcrumbs={listLinks}
                component={
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 7}}>
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 7}}>
                                <FormControl
                                    variant="standard"
                                    sx={{ minWidth: '30vh', marginBottom: errors.serviceId ? '13px' : '35px' }}
                                    error={!!errors.serviceId}
                                >
                                    <InputLabel>{strings.service}</InputLabel>
                                    <Controller
                                        name="serviceId"
                                        control={control}
                                        rules={{ required: strings.serviceRequired }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                value={field.value?.toString() ?? ""}
                                                onChange={(e) => field.onChange(+e.target.value)}
                                                label={strings.service}
                                                labelId="serviceLabel"
                                                endAdornment={
                                                    field.value && (
                                                        <IconButton
                                                            onClick={() => field.onChange(undefined)}
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    )
                                                }
                                            >
                                                {services?.map((s) => (
                                                    <MenuItem key={s.id} value={s.id.toString()}>
                                                        {s.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    {errors.serviceId && (
                                        <Typography variant="caption" color="error" sx={{ textAlign: 'left', paddingTop: "3px" }}>
                                            {errors.serviceId.message}
                                        </Typography>
                                    )}
                                </FormControl>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Controller
                                        name="dateFrom"
                                        control={control}
                                        rules={{
                                            validate: validateDateFrom,
                                            required: strings.dateFromRequired,
                                        }}
                                        render={({ field }) => (
                                            <DatePicker
                                                label={strings.dateFrom}
                                                inputFormat="DD.MM.YYYY"
                                                value={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        sx={{ minWidth: '30vh', height: '80px' }}
                                                        error={!!errors.dateFrom}
                                                        helperText={errors.dateFrom?.message}
                                                    />
                                                )}
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: ['today'],
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Controller
                                        name="dateTo"
                                        control={control}
                                        rules={{
                                            validate: validateDateTo,
                                            required: strings.dateToRequired,
                                        }}
                                        render={({ field }) => (
                                            <DatePicker
                                                label={strings.dateTo}
                                                inputFormat="DD.MM.YYYY"
                                                value={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        sx={{ minWidth: '30vh', height: '80px' }}
                                                        error={!!errors.dateTo}
                                                        helperText={errors.dateTo?.message}
                                                    />
                                                )}
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: ['today'],
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                                <Button variant="contained" type="submit" style={{marginBottom: '20px'}}>
                                    {strings.filter}
                                </Button>
                            </Box>
                            {shouldShowDiagnosticData ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', gap: 7}}>
                                    <Box sx={{width: '30%'}}>
                                        <ChartComponent
                                            series={[percentageListenedTracks !== null ? [parseFloat(percentageListenedTracks.toFixed(2))] : -1]}
                                            labels={[strings.accountsWithListenedTracks]}
                                            type={"radialBar"}
                                            inverseColors={false}
                                        />
                                        <Button variant="contained" startIcon={<FileDownloadIcon/>}
                                                onClick={() => exportAccountsWithoutListenedTracks()}
                                                className="mt-3">
                                            {strings.exportAccountsWithoutListenedTracks}
                                        </Button>
                                    </Box>
                                    <Box sx={{width: '30%'}}>
                                        <ChartComponent
                                            series={[percentageWithListenedTracksDayBefore !== null ? [parseFloat(percentageWithListenedTracksDayBefore.toFixed(2))] : -1]}
                                            labels={[[strings.accountsWithListenedTracksDayBeforeFirstPart, strings.accountsWithListenedTracksDayBeforeSecondPart]]}
                                            type={"radialBar"}
                                            inverseColors={false}
                                        />
                                        <Button variant="contained" startIcon={<FileDownloadIcon/>}
                                                onClick={() => exportAccountsWithListenedTracksDayBefore()}
                                                className="mt-3">
                                            {strings.exportAccountsWithListenedTracksDayBefore}
                                        </Button>
                                    </Box>
                                    <Box sx={{width: '30%'}}>
                                        <ChartComponent
                                            series={[percentageIncorrectPassword !== null ? [parseFloat(percentageIncorrectPassword.toFixed(2))] : -1]}
                                            labels={[strings.accountsWithIncorrectPassword]}
                                            type={"radialBar"}
                                            inverseColors={true}
                                        />
                                        <Button variant="contained" startIcon={<FileDownloadIcon/>}
                                                onClick={() => exportAccountsWithIncorrectPassword()}
                                                className="mt-3">
                                            {strings.exportAccountsWithIncorrectPassword}
                                        </Button>
                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    marginTop: '100px'
                                }}>
                                    <Typography style={{
                                        fontSize: "1.5rem",
                                        color: 'gray'
                                    }}>{strings.addFiltersMessage}</Typography>
                                </Box>
                            )}
                        </Box>
                    </form>
                }
            />
        </>
    );
}
