import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {Box} from "@mui/system";
import React, {useEffect, useState} from "react";
import {SideBarPage} from "../../components/SideBarPage";
import {strings} from "../../localization/Localization";
import {AccountGroupDTO} from "../../models/AccountGroupDTO";
import {SessionCreationDTO} from "../../models/SessionCreationDTO";
import {AccountGroupService} from "../../services/AccountGroupService";
import {SessionService} from "../../services/SessionService";
import {Routes} from "../../router/Routes";
import {ActionType} from "../../models/ActionType";
import {SessionActionCreationDTO} from "../../models/SessionActionCreationDTO";
import {ActionTypeService} from "../../services/ActionTypeService";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {Session} from "../../models/Session";
import {useNavigate, useParams} from "react-router-dom";
import {Close, Edit} from "@mui/icons-material";
import {PaginatedSelect} from "../../components/PaginatedSelect";
import {CenteredCardLayout} from "../../components/CenteredCardLayout";
import {showErrorDialog, showSuccessDialog} from "../../common/Dialogs";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {BehaviorDTO} from "../../models/BehaviorDTO";
import {BehaviorService} from "../../services/BehaviorService";
import {RegionDTO} from "../../models/RegionDTO";
import {ProxyDTO} from "../../models/ProxyDTO";
import {SessionPreferredProxyCreationDTO} from "../../models/SessionPreferredProxyCreationDTO";
import {ProxyService} from "../../services/ProxyService";
import {ClientDTO} from "../../models/ClientDTO";
import {ClientService} from "../../services/ClientService";
import {GenresService} from "../../services/GenresService";
import {GenreDTO} from "../../models/GenreDTO";
import {ActionTypeNomenclature} from "../../models/nomenclatures/ActionTypeNomenclature";
import {ServiceCategories} from "../../common/ServiceCategories";
import {BotsDialog} from "../../components/BotsDialog";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
  updateKey?: (key: string | undefined) => void;
};
class PreferredProxyFormData {
  region: RegionDTO;
  proxy: ProxyDTO | undefined;

  constructor(region: RegionDTO, proxy: ProxyDTO | undefined) {
    this.region = region;
    this.proxy = proxy ? new ProxyDTO(proxy) : undefined;
  }
}

type Copy = {
  accountGroup: AccountGroupDTO | undefined;
  name: string;
};

class SessionFormData {
  name?: string;
  accountGroup?: AccountGroupDTO;
  active: boolean;
  useProxy: boolean;
  actions: SessionActionCreationDTO[];
  behavior: BehaviorDTO | undefined;
  preferredProxies: PreferredProxyFormData[];
  maxDurationInSeconds: number | undefined;
  loops: number | undefined;
  client:ClientDTO|undefined;
  copyScenario:Session | undefined;
  switchAccountGroupOnFinish: boolean;
  copies: Copy[];

  constructor(json: SessionFormData) {
      this.name = json.name;
      this.accountGroup = json.accountGroup;
      this.active = json.active;
      this.useProxy = json.useProxy;
      this.actions = json.actions;
      this.behavior = json.behavior;
      this.preferredProxies = json.preferredProxies;
      this.maxDurationInSeconds = json.maxDurationInSeconds;
      this.loops = json.loops;
      this.client = json.client;
      this.copyScenario = json.copyScenario;
      this.switchAccountGroupOnFinish = json.switchAccountGroupOnFinish;
      this.copies = json.copies;
  }

  toSessionCreationDTO(): SessionCreationDTO {
    const preferredProxies = this.preferredProxies.filter(preferredProxy => preferredProxy.proxy).map(preferredProxy => new SessionPreferredProxyCreationDTO(
      preferredProxy.region.id,
      preferredProxy.proxy!.id,
    ));

    return new SessionCreationDTO(
        {
          name: this.name,
          accountGroupId: this.accountGroup?.id,
          active: this.active,
          useProxy: this.useProxy,
          actions: this.actions,
          behaviorId: this.behavior?.id,
          preferredProxies: this.useProxy ? preferredProxies : undefined,
          maxDurationInSeconds: this.maxDurationInSeconds,
          loops: this.loops,
          clientId: this.client?.id,
          switchAccountGroupOnFinish: this.switchAccountGroupOnFinish,
        }
    );
  }
}

export function AddEditSession({updateKey}:Props) {
  const {id} = useParams();
  const isAddMode = !id;
  const listLinks = [
    { label:  strings.dashboard, currentlyOpened: false, href:Routes.HOME},
    { label:  strings.sessions, currentlyOpened: false, href:Routes.SESSIONS},
    { label:  isAddMode ? strings.addSession : strings.editSession, currentlyOpened: true},
  ]
  function createEmptyAction(): SessionActionCreationDTO {
    return {
      actionTypeId: "",
      sourceKey: undefined,
      minDurationInSeconds: undefined,
      maxDurationInSeconds: undefined,
      useFromShared: false,
      minTrackDurationInSeconds: undefined,
      maxTrackDurationInSeconds: undefined,
    };
  }
  const [actionTypes, setActionTypes] = useState<ActionType[]>([]);
  const [sessionData, setSessionData] = useState<Session | null>(null);
  const [genres, setGenres] = useState<GenreDTO[]>([]);
  const [copyScenarioDialogOpen, setCopyScenarioDialogOpen] = React.useState(false);

  const actionTypeOf = new Map<number, ActionType>();

  for (const actionType of actionTypes) {
    actionTypeOf.set(+actionType.id, actionType);
  }

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<SessionFormData>({
    defaultValues: {
      actions: [createEmptyAction()],
      accountGroup: undefined,
      name: "",
      useProxy: false,
      active: true,
      behavior: undefined,
      preferredProxies: [],
      maxDurationInSeconds: undefined,
      loops: 1,
      client: undefined,
      switchAccountGroupOnFinish: false,
      copies: [{ accountGroup: undefined, name: '' }],
    },
    mode: "onChange",
  });
  const { fields, insert, remove } = useFieldArray({
    control,
    name: "actions",
  });

  const preferredProxiesArray = useFieldArray({
    control,
    name: "preferredProxies",
  });

  const replacePreferredProxies = preferredProxiesArray.replace;

  watch();

  const watchFieldArray = watch("actions");
  const watchPreferredProxies = watch("preferredProxies", preferredProxiesArray.fields);

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const copies = watch("copies", [{ accountGroup: undefined, name: '' }]);

  function onSubmit() {
    return isAddMode ? addSession() : editSession()
  }

  function buildSessionCreationDTO(): SessionCreationDTO {
    return new SessionFormData(getValues()).toSessionCreationDTO();
  }

  function addSession() {
    const sessionCreationDTO = buildSessionCreationDTO();

    SessionService.createSession(sessionCreationDTO)
      .then(() => {
        showSuccessDialog(strings.success, strings.sessionAddedSuccessfully, strings.ok).then(_ => { });
        navigate(Routes.SESSIONS);
      })
      .catch((error) => {
        const message = ErrorHandler.parseErrorMessage(error);
        showErrorDialog(strings.error, message, strings.ok).then(_ => { });
      });
  }
  function editSession() {
    const session = buildSessionCreationDTO();

    if (sessionData === null) {
      return;
    }

    SessionService.editSession(session, sessionData?.id)
      .then(() => {
        showSuccessDialog(strings.success, strings.sessionEditedSuccessfully, strings.ok).then(_ => { });
        navigate(Routes.SESSIONS);
      })
      .catch((error) => {
        const message = ErrorHandler.parseErrorMessage(error);
        showErrorDialog(strings.error, message, strings.ok).then(_ => { });
      });
  }

  function navigateBack() {
    navigate(Routes.SESSIONS, {});
  }

  function handleRemove(index: number) {
    remove(index);
  }

  function handleAdd(index: number) {
    insert(index + 1, createEmptyAction());
  }

  const handleOpenCopyScenarioDialog = () => {
    setCopyScenarioDialogOpen(true);
  };

  const handleCloseCopyScenarioDialog = () => {
    setCopyScenarioDialogOpen(false);
  };

  const handleAddCopy = () => {
    setValue("copies", [...copies, { accountGroup: undefined, name: '' }]);
  };

  const handleConfirmCopyScenario = async () => {
    const isValid = await trigger(["copies"]);

    if (isValid && copies.length > 0) {
      try {
        const values = getValues();
        for (const copy of copies) {
          const sessionFormData = new SessionFormData({
            ...values,
            name: copy.name,
            accountGroup: copy.accountGroup,
          } as SessionFormData);

          const newSession = sessionFormData.toSessionCreationDTO();

          await SessionService.createSession(newSession);
        }

        showSuccessDialog(strings.success, strings.successCopy, strings.ok).then(_ => {
        });
        navigate(Routes.SESSIONS);
      } catch (error) {
        const message = ErrorHandler.parseErrorMessage(error);
        showErrorDialog(strings.error, message, strings.ok).then(_ => {
        });
      }
      handleCloseCopyScenarioDialog();
    }
  };

  const handleRemoveCopy = (index: number) => {
    const newCopies = copies.filter((_, i) => i !== index);
    setValue("copies", newCopies);
  };

  const handleCopyChange = (index: number, field: keyof typeof copies[0], value: any) => {
    const newCopies = [...copies];
    newCopies[index][field] = value;
    setValue("copies", newCopies);
  };

  const validateAccountGroup = (value: AccountGroupDTO | undefined) => {
    if (!value) return strings.accountGroupRequired;
  };

  const validateScenarioName = (value: string) => {
    if (!value) return strings.scenarioNameRequired;
  };

  function isSourceKeyRequired(index: number, item: SessionActionCreationDTO) {
    const values = getValues("actions");
    let result = actionTypes.find(
      (ac) => ac.id === Number(values[index]?.actionTypeId)
    );
    if (
      result?.sourceKeyRequired &&
      (item.sourceKey === "" || item.sourceKey === undefined)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function checkMinMax(item: SessionActionCreationDTO) {
    if (
      item.minDurationInSeconds !== undefined &&
      item.maxDurationInSeconds !== undefined
    ) {
      if (
        Number(item.minDurationInSeconds) > Number(item.maxDurationInSeconds)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  function checkMinMaxTrackDuration(item: SessionActionCreationDTO) {
    if (
      item.minTrackDurationInSeconds !== undefined &&
      item.maxTrackDurationInSeconds !== undefined
    ) {
      if (
        Number(item.minTrackDurationInSeconds) > Number(item.maxTrackDurationInSeconds)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  function checkProbabilityError(value: number | null | undefined): boolean {
    return ((!value && value !== 0) || (value >= 0 && value <= 1));
  }

  function validateMaxSessionDuration(maxSessionDurationInSeconds: number | undefined): string | undefined {
    if (maxSessionDurationInSeconds === undefined) {
      return undefined;
    }

    if (maxSessionDurationInSeconds > 60 * 60 * 24) {
      return strings.maxSessionDurationInSecondsExceededMessage;
    }

    return undefined;
  }

  function validateLoops(loops: number | undefined): string | undefined {
    if (loops === undefined) {
      return strings.loopsRequired;
    }

    if (loops < 1) {
      return strings.loopsInvalid;
    }

    return undefined;
  }

  function checkValueOfActionType(index: number) {
    if (!isAddMode && getValues(`actions.${index}.actionTypeId`)) {
      return true;
    } else if (!isAddMode && !getValues(`actions.${index}.actionTypeId`)) {
      return false;
    } else if (isAddMode && getValues(`actions.${index}.actionTypeId`)) {
      return true;
    } else if (isAddMode && !getValues(`actions.${index}.actionTypeId`)) {
      return false;
    }
  }
  async function onCopyScenarioChange(session : Session | undefined): Promise<void> {
    if(session){
      const accountGroupRegions = await AccountGroupService.getAllAppearingRegionsInAccountGroup(session.accountGroup.id);

      setSessionData(new Session(
          {
            ...session,
            accountGroupRegions: accountGroupRegions,
          }
      ));
    }
  }
  async function onAccountGroupChanged(accountGroup: AccountGroupDTO | undefined): Promise<void> {
    if (!accountGroup) {
      replacePreferredProxies([]);
    } else {
      const accountGroupRegions = await AccountGroupService.getAllAppearingRegionsInAccountGroup(accountGroup.id);

      const preferredProxyOfRegion = new Map<number, ProxyDTO>();
      preferredProxiesArray.fields?.forEach(preferredProxy => {
        const proxy = preferredProxy.proxy;

        if (proxy) {
          preferredProxyOfRegion.set(preferredProxy.region.id, proxy)
        }
      });

      replacePreferredProxies(accountGroupRegions.map(region => new PreferredProxyFormData(
          region,
          preferredProxyOfRegion.get(region.id),
      )));
    }
  }

  function isGeneratePlaylist(actionTypeId: string | number | undefined) {
    if (!actionTypeId) {
      return false;
    }

    const id = Number(actionTypeId);

    return actionTypeOf.get(id)?.type === ActionTypeNomenclature.GENERATE_PLAYLIST;
  }

  function isSearchAndPlayFromPlaylist(actionTypeId: string | number | undefined) {
    if (!actionTypeId) {
      return false;
    }

    const id = Number(actionTypeId);

    return actionTypeOf.get(id)?.type === ActionTypeNomenclature.SEARCH_AND_PLAY_FROM_PLAYLIST;
  }

  function isPlaylist(actionTypeId: string | number | undefined){
    const id = Number(actionTypeId);

    return actionTypeOf.get(id)?.type === "playlist";

  }

  function isScroll (actionTypeId: string | number | undefined) {
    const id = Number(actionTypeId);

    return actionTypeOf.get(id)?.type === "scroll";
  }

  useEffect(() => {
    if(isAddMode){
      setValue("active", true)
    }

    async function getAllGenres(): Promise<void> {
      const genres = await GenresService.getAllGenres();
      setGenres(genres);
    }

    async function getSessionById(id: number): Promise<void> {
      const session = await SessionService.getSessionById(id);
      const accountGroupRegions = await AccountGroupService.getAllAppearingRegionsInAccountGroup(session.accountGroup.id);
      if(updateKey){
        updateKey(session.key)
      }
      setSessionData(new Session(
          {
            ...session,
            accountGroupRegions: accountGroupRegions,
          }
      ));
    }
    async function loadData(): Promise<void> {
      await getAllGenres();

      if (!isAddMode) {
        await getSessionById(parseInt(id));
      }
    }
    loadData().then(_ => { });
  }, [isAddMode, id, setValue]);

  const accountGroup = watch("accountGroup");
  const accountGroupServiceCategory = accountGroup?.service?.serviceCategoryId;

  useEffect(() => {
    async function getAllActionTypes(): Promise<void> {
      const actionTypes = await ActionTypeService.getAllActionTypes(accountGroupServiceCategory);
      setActionTypes(actionTypes);
    }

    if (!accountGroupServiceCategory) {
      setActionTypes([]);
    } else {
      getAllActionTypes();
    }
  }, [accountGroupServiceCategory]);

  useEffect(() => {
    if (sessionData) {
      const session = sessionData;
      const preferredProxyOfRegion = new Map<number, ProxyDTO>();
      session.preferredProxies?.forEach(preferredProxy => preferredProxyOfRegion.set(preferredProxy.region.id, preferredProxy.proxy));

      setValue("name", session.name);
      setValue("accountGroup", session.accountGroup);
      setValue("active", session.active ? true : false);
      setValue("useProxy", session.useProxy ? true : false);
      setValue("actions", session.actions);
      setValue("behavior", session.behavior);
      setValue("maxDurationInSeconds", session.maxDurationInSeconds);
      setValue("loops", session.loops);
      setValue("client",session.client);
      setValue("switchAccountGroupOnFinish", session.switchAccountGroupOnFinish);

      replacePreferredProxies(session?.accountGroupRegions?.map(region => new PreferredProxyFormData(
          region,
          preferredProxyOfRegion.get(region.id),
      )) ?? []);
    }
  }, [replacePreferredProxies, sessionData, setValue]);

  return (
    <>
      <SideBarPage
        pageTitle={isAddMode ? strings.addSession : strings.editSession}
        breadcrumbs={listLinks}
        component={
          <>
            <BotsDialog
                open={copyScenarioDialogOpen}
                nonReversableAction={false}
                onClose={handleCloseCopyScenarioDialog}
                onConfirm={handleConfirmCopyScenario}
                title={strings.copyScenario}
                confirmActionText={strings.copy}
                content={
                  <Box>
                    {copies.map((copy, index) => (
                        <Grid container key={index} className='mb-3' alignItems="center" spacing={6}>
                          <Grid item xs={5}>
                            <FormControl fullWidth error={!!errors.copies?.[index]?.accountGroup}>
                              <Controller
                                  name={`copies.${index}.accountGroup`}
                                  control={control}
                                  rules={{ validate: validateAccountGroup, required: strings.accountGroupRequired }}
                                  render={({ field }) => (
                                      <PaginatedSelect<AccountGroupDTO>
                                          value={field.value}
                                          onChange={(value) => {
                                            field.onChange(value);
                                            handleCopyChange(index, 'accountGroup', value);
                                          }}
                                          label={strings.accountGroup}
                                          valueMapper={(item) => item.id.toString()}
                                          keyMapper={(item) => item.id.toString()}
                                          itemMapper={(item) => <>{item.name}</>}
                                          labelMapper={(item) => item.name}
                                          dataFetcher={(page, size, filter) => {
                                            return AccountGroupService.getAllAccountGroupsPaged(page, size, filter, sessionData?.accountGroup.service.id);
                                          }}
                                          inputProps={{
                                            variant: undefined,
                                            error: !!errors.copies?.[index]?.accountGroup,
                                            helperText: errors.copies?.[index]?.accountGroup?.message
                                          }}
                                      />
                                  )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={5}>
                            <Controller
                                name={`copies.${index}.name`}
                                control={control}
                                rules={{ validate: validateScenarioName,
                                         required: strings.scenarioNameRequired
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label={strings.scenarioName}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.target.value);
                                          handleCopyChange(index, 'name', e.target.value);
                                        }}
                                        error={!!errors.copies?.[index]?.name}
                                        helperText={errors.copies?.[index]?.name?.message}
                                    />
                                )}
                            />
                          </Grid>
                          <Grid item xs={2} sx={{ marginBottom: errors.copies?.[index]?.name ? '25px' : '0' }}>
                            <IconButton onClick={() => handleRemoveCopy(index)} sx={{ color: 'red' }}>
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                    ))}
                    <Button variant='contained' onClick={handleAddCopy} fullWidth>
                      {strings.addCopy}
                    </Button>
                  </Box>
                }
            />
          <CenteredCardLayout minWidth={"61%"} maxWidth={"61%"}>
            {!isAddMode && <Box sx={{display: 'flex', justifyContent: 'flex-start', marginBottom: 5}}>
              <Button variant='contained' onClick={handleOpenCopyScenarioDialog}>
                {strings.copyScenario}
              </Button>
            </Box>}
            {
              isAddMode ? (<FormControl
                  fullWidth
                  sx={{textAlign: "left"}}
                  className="mb-3"
              >
                <Controller
                  name="copyScenario"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PaginatedSelect<Session>
                      value={value}
                      onChange={(value) => {
                        onChange(value);
                        onCopyScenarioChange(value).then(_ => { });
                      }
                      }
                      label={strings.session}
                      valueMapper={(item) => item.id.toString()}
                      keyMapper={(item) => item.id.toString()}
                      itemMapper={(item) => <>{item.name}</>}
                      labelMapper={(item) => item.name}
                      dataFetcher={(page, size, filter) => {
                        return SessionService.getAllSessionsPaged(page, size, null, undefined, null, filter, undefined, undefined, undefined);
                      }}
                      inputProps={{
                        variant: undefined,
                      }}
                    />

                  )}
                />
              </FormControl>) : (<></>)
            }
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sm={4} md={6}>
                <Controller
                  name="name"
                  rules={{
                    required: true,
                  }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-3"
                      label={strings.name}
                      type="text"
                      variant="outlined"
                      error={errors.name !== undefined}
                      fullWidth
                    />
                  )}
                />
                <FormControl
                  fullWidth
                  sx={{ textAlign: "left" }}
                  error={errors.accountGroup !== undefined}
                >
                  <Controller
                    name="accountGroup"
                    rules={{
                      required: true,
                    }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <PaginatedSelect<AccountGroupDTO>
                        value={value}
                        onChange={(value) => {
                            onChange(value);
                            onAccountGroupChanged(value).then(_ => {});
                          }
                        }
                        label={strings.accountGroup}
                        valueMapper={(item) => item.id.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return AccountGroupService.getAllAccountGroupsPaged(
                            page,
                            size,
                            filter
                          );
                        }}
                        inputProps={{
                          variant: undefined,
                          error: errors.accountGroup !== undefined,
                          helperText:
                            errors.accountGroup !== undefined
                              ? errors.accountGroup.message
                              : "",
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ textAlign: "left" }}
                  error={errors.client !== undefined}
                  className="mt-3"
                >
                  <Controller
                    name="client"
                    rules={{
                      required: false,
                    }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <PaginatedSelect<ClientDTO>
                        value={value}
                        onChange={(value) => {
                          onChange(value);
                        }
                        }
                        label={strings.client}
                        valueMapper={(item) => item.id.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return ClientService.getAllClientsPaged(
                            page,
                            size,
                            filter,
                            null,
                            null,
                            undefined

                          );
                        }}
                        inputProps={{
                          variant: undefined,
                          error: errors.client !== undefined,
                          helperText:
                            errors.client !== undefined
                              ? errors.client.message
                              : "",
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ textAlign: "left" }}
                  error={errors.maxDurationInSeconds !== undefined}
                >
                  <TextField
                    {...register(`maxDurationInSeconds`, {
                      valueAsNumber: true,
                      validate: validateMaxSessionDuration,
                    })}
                    className="mt-3"
                    sx={{ width: "100%" }}
                    fullWidth
                    name={`maxDurationInSeconds`}
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    label={strings.maxSessionDurationInSeconds}
                    error={
                      errors.maxDurationInSeconds !== undefined
                    }
                    helperText={errors.maxDurationInSeconds?.message}
                    InputLabelProps={{
                      shrink: getValues("maxDurationInSeconds") !== undefined,
                    }}
                  />
                </FormControl>
                <FormControl fullWidth
                  sx={{ textAlign: "left" }}
                  error={errors.loops !== undefined}
                >
                  <TextField
                    {...register(`loops`, {
                      valueAsNumber: true,
                      validate: validateLoops,
                      required: true,
                    })}
                    className="mt-3"
                    sx={{ width: "100%" }}
                    fullWidth
                    name={`loops`}
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    label={strings.loops}
                    error={errors.loops !== undefined}
                    helperText={errors.loops?.message}
                    required
                  />
                </FormControl>
                <Box className="mt-4">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          name="active"
                          control={control}
                          defaultValue={false}
                          render={({ field: { value, ref, ...field } }) => (
                            <Checkbox
                              {...field}
                              inputRef={ref}
                              checked={!!value}
                              color="primary"
                              size={"medium"}
                              disableRipple
                            />
                          )}
                        />
                      }
                      label={strings.active}
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      control={
                        <Controller
                          name="switchAccountGroupOnFinish"
                          control={control}
                          defaultValue={false}
                          render={({ field: { value, ref, ...field } }) => (
                            <Checkbox
                              {...field}
                              inputRef={ref}
                              checked={!!value}
                              color="primary"
                              size={"medium"}
                              disableRipple
                            />
                          )}
                        />
                      }
                      label={strings.switchAccountGroupOnFinish}
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      control={
                        <Controller
                          name="useProxy"
                          control={control}
                          defaultValue={false}
                          render={({ field: { value, ref, ...field } }) => (
                            <Checkbox
                              {...field}
                              inputRef={ref}
                              checked={!!value}
                              color="primary"
                              size={"medium"}
                              disableRipple
                            />
                          )}
                        />
                      }
                      label={strings.useProxy}
                      labelPlacement="end"
                    />
                  </FormGroup>
                  <FormControl
                    fullWidth
                    sx={{ textAlign: "left" }}
                    error={errors.accountGroup !== undefined}
                  >
                    <Controller
                      name="behavior"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <PaginatedSelect<BehaviorDTO>
                          value={value}
                          onChange={onChange}
                          label={strings.behavior}
                          valueMapper={(item) => item.id.toString()}
                          keyMapper={(item) => item.id.toString()}
                          itemMapper={(item) => <>{item.name}</>}
                          labelMapper={(item) => item.name}
                          dataFetcher={(page, size, filter) => {
                            return BehaviorService.getAllBehaviorPaged(
                              page,
                              size,
                              filter,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                            );
                          }}
                          inputProps={{
                            className: "mt-3",
                            variant: undefined,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  {
                    getValues("useProxy") && getValues("accountGroup.id") && (
                      <>
                        <div className="d-flex justify-content-center mt-3" style={{ fontWeight: 'bold' }}>{strings.preferredProxies}</div>
                        <TableContainer className="mt-3">
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{strings.key}</TableCell>
                                <TableCell>{strings.name}</TableCell>
                                <TableCell>{strings.labelProxyName}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {watchPreferredProxies.map((preferredProxy, index) => (
                                <TableRow key={preferredProxy.region.id}>
                                  <TableCell>{preferredProxy.region.key}</TableCell>
                                  <TableCell>
                                    {preferredProxy.region.label}
                                  </TableCell>
                                  <TableCell>
                                    <FormControl
                                      fullWidth

                                      sx={{ textAlign: "left" }}
                                    >

                                      <PaginatedSelect<ProxyDTO>
                                        value={preferredProxy.proxy}
                                        onChange={(value) => {
                                          preferredProxiesArray.update(index, new PreferredProxyFormData(
                                            preferredProxy.region,
                                            value,
                                          ));
                                        }
                                        }
                                        label={strings.labelProxyName}
                                        valueMapper={(item) => item.id.toString()}
                                        keyMapper={(item) => item.id?.toString()}
                                        itemMapper={(item) => <>{item.name}</>}
                                        labelMapper={(item) => item.name}
                                        dataFetcher={(page, size) => {
                                          return ProxyService.getAllProxiesPaged2(
                                            page,
                                            size,
                                            null,
                                            null,
                                            preferredProxy.region.id
                                          );
                                        }}
                                        inputProps={{
                                          variant: undefined,
                                          error: errors.preferredProxies?.[index] !== undefined,
                                          helperText:
                                            errors.preferredProxies?.[index] !== undefined
                                              ? errors.preferredProxies.message
                                              : "",

                                        }}
                                      />
                                    </FormControl>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )
                  }
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                {controlledFields.map((item, index) => (
                  <div key={item.id}>
                    <>
                      <FormControl
                        fullWidth
                        sx={{ textAlign: "left" }}
                        error={
                          errors.actions?.[index]?.actionTypeId !== undefined
                        }
                      >
                        <InputLabel
                          shrink={
                            getValues(`actions.${index}.actionTypeId`)
                              ? true
                              : false
                          }
                        >
                          {strings.action}
                        </InputLabel>
                        <Controller
                          name={`actions.${index}.actionTypeId`}
                          rules={{
                            required: true,
                          }}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              value={value}
                              onChange={onChange}
                              input={
                                <OutlinedInput
                                  label={strings.action}
                                  notched={checkValueOfActionType(index)}
                                />
                              }
                            >
                              {actionTypes.map((actionType) => (
                                <MenuItem
                                  value={actionType.id}
                                  key={actionType.id}
                                >
                                  {actionType.label}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>

                      <TextField
                        {...register(`actions.${index}.sourceKey`, {
                          required: isSourceKeyRequired(index, item),
                        })}
                        className="mt-3"
                        sx={{ width: "100%" }}
                        fullWidth
                        name={`actions[${index}].sourceKey`}
                        label={strings.sourceKey}
                        error={errors.actions?.[index]?.sourceKey !== undefined}
                      />
                      <TextField
                        {...register(`actions.${index}.minDurationInSeconds`, {
                          required: true,
                          valueAsNumber: true,
                        })}
                        className="mt-3"
                        sx={{ width: "100%" }}
                        fullWidth
                        name={`actions[${index}].minDurationInSeconds`}
                        type="number"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        label={strings.minDuration}
                        error={
                          checkMinMax(item) ||
                          errors.actions?.[index]?.minDurationInSeconds !==
                          undefined
                        }
                        helperText={checkMinMax(item) && strings.minMax}
                      />
                      <TextField
                        {...register(`actions.${index}.maxDurationInSeconds`, {
                          required: true,
                          valueAsNumber: true,
                        })}
                        className="mt-3"
                        sx={{ width: "100%" }}
                        fullWidth
                        name={`actions[${index}].maxDurationInSeconds`}
                        type="number"
                        label={strings.maxDuration}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        error={
                          checkMinMax(item) ||
                          errors.actions?.[index]?.maxDurationInSeconds !==
                          undefined
                        }
                        helperText={checkMinMax(item) && strings.minMax}
                      />
                      {(accountGroup?.service?.serviceCategory?.type === ServiceCategories.STREAMING || isScroll(getValues(`actions.${index}.actionTypeId`))) && <>
                        <TextField
                          {...register(`actions.${index}.minTrackDurationInSeconds`, {
                            required: false,
                            valueAsNumber: true,
                          })}
                          className="mt-3"
                          sx={{width: "100%"}}
                          fullWidth
                          name={`actions[${index}].minTrackDurationInSeconds`}
                          type="number"
                          label={strings.minTrackDurationInSeconds}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          error={checkMinMaxTrackDuration(item)}
                          helperText={checkMinMaxTrackDuration(item) && strings.minMax}
                        />
                        <TextField
                          {...register(`actions.${index}.maxTrackDurationInSeconds`, {
                            required: false,
                            valueAsNumber: true,
                          })}
                          className="mt-3"
                          sx={{width: "100%"}}
                          fullWidth
                          name={`actions[${index}].maxTrackDurationInSeconds`}
                          type="number"
                          label={strings.maxTrackDurationInSeconds}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          error={
                            checkMinMaxTrackDuration(item) ||
                            errors.actions?.[index]?.maxTrackDurationInSeconds !==
                            undefined
                          }
                          helperText={checkMinMaxTrackDuration(item) && strings.minMax}
                        />
                      </>}
                      {isGeneratePlaylist(getValues(`actions.${index}.actionTypeId`)) &&
                        <>
                          <TextField
                            {...register(`actions.${index}.maxPlaylistDurationInSeconds`, {
                              required: false,
                              valueAsNumber: true,
                            })}
                            className="mt-3"
                            sx={{ width: "100%" }}
                            fullWidth
                            name={`actions[${index}].maxPlaylistDurationInSeconds`}
                            type="number"
                            label={strings.maxPlaylistDurationInSeconds}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <TextField
                            {...register(`actions.${index}.playlistGenerationProbability`, {
                              required: false,
                              valueAsNumber: true,
                              validate: value => checkProbabilityError(value)
                            })}
                            className="mt-3"
                            sx={{ width: "100%" }}
                            fullWidth
                            name={`actions[${index}].playlistGenerationProbability`}
                            type="number"
                            label={strings.playlistGenerationProbability}
                            error={!checkProbabilityError(item.playlistGenerationProbability)}
                            helperText={!checkProbabilityError(item.playlistGenerationProbability) && strings.probabilityBetweenZeroAndOne}
                          />
                          <FormControl
                              fullWidth
                              sx={{ textAlign: "left" }}
                              className="mt-3"
                          >
                            <InputLabel
                                shrink={
                                  getValues(`actions.${index}.playlistGenerationMainGenre`)
                                      ? true
                                      : false
                                }
                            >
                              {strings.mainGenre}
                            </InputLabel>
                            <Controller
                                name={`actions.${index}.playlistGenerationMainGenre`}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        endAdornment = {
                                          <>
                                            {
                                                value && <IconButton onClick={() => setValue(`actions.${index}.playlistGenerationMainGenre`, '')}>
                                                  <Close fontSize="small"/>
                                                </IconButton>
                                            }
                                          </>
                                        }
                                        value={value ?? ''}
                                        onChange={onChange}
                                        input={
                                          <OutlinedInput
                                              label={strings.mainGenre}
                                              notched={getValues(`actions.${index}.playlistGenerationMainGenre`) ? true : false}
                                          />
                                        }
                                    >
                                      {genres.map((genre) => (
                                          <MenuItem
                                              value={genre.key}
                                              key={genre.key}
                                          >
                                            {genre.name}
                                          </MenuItem>
                                      ))}
                                    </Select>
                                )}
                            />
                          </FormControl>
                          <TextField
                              {...register(`actions.${index}.playlistGenerationMainGenreProbability`, {
                                required: false,
                                valueAsNumber: true,
                                validate: value => checkProbabilityError(value)
                              })}
                              className="mt-3"
                              sx={{ width: "100%" }}
                              fullWidth
                              name={`actions[${index}].playlistGenerationMainGenreProbability`}
                              type="number"
                              label={strings.mainGenreProbability}
                              error={!checkProbabilityError(item.playlistGenerationMainGenreProbability)}
                              helperText={!checkProbabilityError(item.playlistGenerationMainGenreProbability) && strings.probabilityBetweenZeroAndOne}
                          />
                          <TextField
                              {...register(`actions.${index}.playlistGenerationGenres`, {
                                required: false,
                              })}
                              className="mt-3"
                              sx={{ width: "100%" }}
                              fullWidth
                              name={`actions[${index}].playlistGenerationGenres`}
                              label={strings.genres}
                          />
                        </>
                      }
                      {isSearchAndPlayFromPlaylist(getValues(`actions.${index}.actionTypeId`)) &&
                          <>
                            <FormControl
                                fullWidth
                                sx={{ textAlign: "left" }}
                                className="mt-3"
                            >
                              <InputLabel
                                  shrink={
                                    getValues(`actions.${index}.playlistGenerationMainGenre`)
                                        ? true
                                        : false
                                  }
                              >
                                {strings.mainGenre}
                              </InputLabel>
                              <Controller
                                  name={`actions.${index}.playlistGenerationMainGenre`}
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                      <Select
                                          endAdornment = {
                                            <>
                                              {
                                                  value && <IconButton onClick={() => setValue(`actions.${index}.playlistGenerationMainGenre`, '')}>
                                                    <Close fontSize="small"/>
                                                  </IconButton>
                                              }
                                            </>
                                          }
                                          value={value ?? ''}
                                          onChange={onChange}
                                          input={
                                            <OutlinedInput
                                                label={strings.mainGenre}
                                                notched={getValues(`actions.${index}.playlistGenerationMainGenre`) ? true : false}
                                            />
                                          }
                                      >
                                        {genres.map((genre) => (
                                            <MenuItem
                                                value={genre.key}
                                                key={genre.key}
                                            >
                                              {genre.name}
                                            </MenuItem>
                                        ))}
                                      </Select>
                                  )}
                              />
                            </FormControl>
                            <TextField
                                {...register(`actions.${index}.playlistGenerationMainGenreProbability`, {
                                  required: false,
                                  valueAsNumber: true,
                                  validate: value => checkProbabilityError(value)
                                })}
                                className="mt-3"
                                sx={{ width: "100%" }}
                                fullWidth
                                name={`actions[${index}].playlistGenerationMainGenreProbability`}
                                type="number"
                                label={strings.mainGenreProbability}
                                error={!checkProbabilityError(item.playlistGenerationMainGenreProbability)}
                                helperText={!checkProbabilityError(item.playlistGenerationMainGenreProbability) && strings.probabilityBetweenZeroAndOne}
                            />
                            <TextField
                                {...register(`actions.${index}.playlistGenerationGenres`, {
                                  required: false,
                                })}
                                className="mt-3"
                                sx={{ width: "100%" }}
                                fullWidth
                                name={`actions[${index}].playlistGenerationGenres`}
                                label={strings.genres}
                            />
                          </>
                      }
                      {isPlaylist(getValues(`actions.${index}.actionTypeId`)) ? <>
                        <FormControlLabel
                          control={
                          <Controller
                              name={`actions.${index}.addToFavorites`}
                              control={control}
                              defaultValue={false}
                              render={({ field: { value, ref, ...field } }) => (
                                  <Checkbox
                                      {...field}
                                      inputRef={ref}
                                      checked={!!value}
                                      color="primary"
                                      size={"medium"}
                                      disableRipple/>
                              )}/>}
                          label={strings.addToFavorites}
                          labelPlacement="end"/><br/>
                        <FormControlLabel
                          control={
                            <Controller
                              name={`actions.${index}.followArtist`}
                              control={control}
                              defaultValue={false}
                              render={({ field: { value, ref, ...field } }) => (
                                  <Checkbox
                                      {...field}
                                      inputRef={ref}
                                      checked={!!value}
                                      color="primary"
                                      size={"medium"}
                                      disableRipple/>
                              )}/>}
                          label={strings.followArtist}
                          labelPlacement="end"/>
                      </> : null}
                      {isScroll(getValues(`actions.${index}.actionTypeId`)) ?

                        <TextField
                          {...register(`actions.${index}.likeProbability`, {
                            required: false,
                            valueAsNumber: true,
                            validate: value => checkProbabilityError(value)
                          })}
                          className="mt-3"
                          sx={{ width: "100%" }}
                          fullWidth
                          name={`actions[${index}].likeProbability`}
                          type="number"
                          label={strings.likeProbability}
                          error={!checkProbabilityError(item.likeProbability)}
                          helperText={!checkProbabilityError(item.likeProbability) && strings.probabilityBetweenZeroAndOne}
                        />
                        : null}
                      <br />
                      <FormControlLabel
                        control={
                          <Controller
                            name={`actions.${index}.useFromShared`}
                            control={control}
                            defaultValue={false}
                            render={({ field: { value, ref, ...field } }) => (
                              <Checkbox
                                {...field}
                                inputRef={ref}
                                checked={!!value}
                                color="primary"
                                size={"medium"}
                                disableRipple
                              />
                            )}
                          />
                        }
                        label={strings.randomizeFromSharedActions}
                        labelPlacement="end"
                      />
                      <div>
                        {index > 0 && (
                          <IconButton
                            onClick={() => {
                              handleRemove(index);
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                        <IconButton onClick={() => handleAdd(index)}>
                          <AddIcon />
                        </IconButton>
                      </div>
                    </>
                  </div>
                ))}
              </Grid>
            </Grid>
            <div className="d-flex justify-content-around">
              {" "}
              <Button
                fullWidth
                className="mt-3"
                variant={"contained"}
                color={"warning"}
                onClick={navigateBack}
              >
                {strings.cancel}
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit(onSubmit)}
                fullWidth
                style={{ marginLeft: "10px" }}
                variant="contained"
                className="mt-3"
                startIcon={!isAddMode && <Edit />}
              >
                {isAddMode ? strings.addSession : strings.saveChanges}
              </Button>
            </div>
          </CenteredCardLayout>
        </>
        }
      />
    </>
  );
}
