import {SideBarPage} from "../../components/SideBarPage";
import {
    Alert,
    Grid,
    Input,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip
} from "@mui/material";
import {strings} from "../../localization/Localization";
import {defaultRowsPerPageOptions, snackbarDurationInMilliseconds} from "../../common/Constants";
import React, {useEffect, useState} from "react";
import {BotsDTO} from "../../models/BotsDTO";
import {BotsService} from "../../services/BotsService";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {Dayjs} from "dayjs";
import {NullableDatePicker} from "../../components/NullableDatePicker";
import '../../css/global.css';
import BlockIcon from '@mui/icons-material/Block';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import {SortingConfiguration} from "../../utils/SortingUtils";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {NumberParser} from "../../utils/NumberParser";
import {TableHeader} from "../../components/TableHeader";
import {Routes} from "../../router/Routes";
import {BotController} from "../../controllers/BotController";
import {PaginationPlus} from "../../components/PaginationPlus";
import {guardedObject} from "../../router/guards/GuardedCallback";
import {PrivilegeGuard, PrivilegeGuardMode} from "../../router/guards/PrivilegeGuard";
import {Privileges} from "../../models/nomenclatures/Privileges";
import {BotStatuses} from "../../common/BotStatuses";
import {TvOff} from "@mui/icons-material";

export function BotsPage() {

    const columns = [
        {label: strings.botId, sortBy: "id", sortable: true},
        {label: strings.botName, sortBy: "name", sortable: true},
        {label: strings.botLastLog, sortBy: "lastLog", sortable: true},
        {label: strings.botLastActionDate, sortBy: "lastActionDate", sortable: true},
        {label: strings.totalScenarios, sortBy:"totalScenarios", sortable:true},
        {label: strings.dateCreated, sortBy: "dateCreated", sortable: true},
        {label: strings.dateModified, sortBy: "dateModified", sortable: true},
        {label: strings.status, sortBy: "status", sortable: false},    
    ]
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:Routes.HOME},
        { label: strings.bots,  currentlyOpened: true },
    ]

    const successMessageKey = "successMessage";
    const [searchParams, setSearchParams] = useSearchParams();

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    const [bots, setBots] = useState<BotsDTO[]>([]);
    const id = NumberParser.parseNumberNullable(searchParams.get("id"));
    const name = searchParams.get("name") ?? "";
    const lastLog = searchParams.get("lastLog") ?? "";
    const lastActionDate = searchParams.get("lastActionDate") ?? null;
    const dateCreated = searchParams.get("dateCreated") ?? null;
    const dateModified = searchParams.get("dateModified") ?? null;
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [totalElements, setTotalElements] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    function navigateBotRow(id: number | undefined): any {
        if (id !== undefined) {
            BotController.navigateToBotDetailsPage(navigate, id).then(_ => {});
        }
    }

    const sortingConfigurationString = searchParams.get("sort");

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function setId(id: number | null): void {
        setSearchParam("id", id?.toString());
    }

    function handleIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        if(!event.target.value){
            setId(null)
        } else setId(+event.target.value);
        setPage(0);
    }

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("name", event.target.value);
        setPage(0);
    }

    function handleLastLogChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("lastLog", event.target.value);
        setPage(0);
    }

    function handleLastActionDateChange(date: Dayjs | null) {
        setSearchParam("lastActionDate",DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function handleDateCreatedChange(date: Dayjs | null) {
        setSearchParam("dateCreated",DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function handleDateModifiedChange(date: Dayjs | null) {
        setSearchParam("dateModified",DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    useEffect(() => {
        setSnackbarOpen(searchParams.has(successMessageKey));
    }, [searchParams]);

    useEffect(() => {
        function getBots(page: number){
            BotsService.getAllBotsPaged(id, page, rowsPerPage,  name, lastLog,
                lastActionDate, dateCreated, dateModified,SortingConfiguration.fromSearchString(sortingConfigurationString)).then((data) => {
                setBots(data.content);
                setTotalElements(data.totalElements);
            })
        }
        getBots(page);
    }, [id, page, rowsPerPage, name, lastLog, lastActionDate, dateCreated, dateModified, sortingConfigurationString]);

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    function getRowLink(entry: BotsDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.READ_BOTS],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.BOT_DETAILS.replace(":id", entry.id.toString())) ?? "";
    }

    return(
        <>
            <SideBarPage pageTitle={strings.bots}  
                breadcrumbs={listLinks}
                component={
                <Grid>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Input name="id" value={id !== undefined ? id : ""} onChange={handleIdChange} placeholder={strings.botIdFilter} style={{width: "auto"}} />
                                    </TableCell>
                                    <TableCell>
                                        <Input name="botName" value={name} onChange={handleNameChange} placeholder={strings.botNameFilter} style={{width: "auto"}} />
                                    </TableCell>
                                    <TableCell>
                                        <Input name="lastLog" value={lastLog} onChange={handleLastLogChange} placeholder={strings.botLastLogFilter} style={{width: "auto"}} />
                                    </TableCell>
                                    <TableCell>
                                        <NullableDatePicker label={strings.botLastActionDate} value={lastActionDate} onChange={handleLastActionDateChange} />
                                    </TableCell>
                                    <TableCell>
                                        <NullableDatePicker label={strings.dateCreated} value={dateCreated} onChange={handleDateCreatedChange} />
                                    </TableCell>
                                    <TableCell>
                                        <NullableDatePicker label={strings.dateModified} value={dateModified} onChange={handleDateModifiedChange} />
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString} updateSort={updateSort}/>
                            </TableHead>
                            <TableBody>
                                {bots?.map((bots) => (
                                    <TableRow
                                        key={bots.id}
                                        hover={true}
                                        className="cursor-pointer"
                                        component={getRowLink(bots) ? Link : TableRow}
                                        to={getRowLink(bots)}
                                        onClick={() => navigateBotRow(bots.id)}
                                    >
                                        <TableCell width={"10%"}>{bots?.id}</TableCell>
                                        <TableCell width={"20%"}>{bots.name}</TableCell>
                                        <Tooltip title={bots.lastLog}><TableCell width={"20%"} className="truncate-table-cell">{bots.lastLog}</TableCell></Tooltip>
                                        <TableCell width={"10%"}>{DateTimeUtils.formatDate(bots.lastActionDate)}</TableCell>
                                        <TableCell width={"10%"}>{bots.totalScenarios}</TableCell>
                                        <TableCell width={"10%"}>{DateTimeUtils.formatDate(bots.dateCreated)}</TableCell>
                                        <TableCell width={"10%"}>{DateTimeUtils.formatDate(bots.dateModified)}</TableCell>
                                        {bots.status === BotStatuses.INACTIVE ? <Tooltip title={"Inactive"}><TableCell width={"10%"}><BlockIcon color={'error'}/></TableCell></Tooltip> : null}
                                        {bots.status === BotStatuses.DIED ? <Tooltip title={"Died"}><TableCell width={"10%"}><TvOff color={'error'}/></TableCell></Tooltip> : null}
                                        {bots.status === BotStatuses.IDLE ? <Tooltip title={"Idle"}><TableCell width={"10%"}><AccessAlarmIcon color={'warning'}/></TableCell></Tooltip> : null}
                                        {bots.status === BotStatuses.ACTIVE ? <Tooltip title={"Active"}><TableCell width={"10%"}><LiveTvIcon color={"success"}/></TableCell></Tooltip> : null}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                        <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                        <TablePagination
                        rowsPerPageOptions={defaultRowsPerPageOptions}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Grid>
                </Grid>
            }/>
            <Snackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                autoHideDuration={snackbarDurationInMilliseconds}>
                <Alert severity="success">
                    {searchParams.get(successMessageKey)}
                </Alert>
            </Snackbar>
        </>
    )
}
