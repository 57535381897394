import React, {useEffect, useState} from "react";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {ScheduledInterval} from "../../models/ScheduledInterval";
import {AccountGroupService} from "../../services/AccountGroupService";
import {BotConditionService} from "../../services/BotConditionService";
import {Session} from "../../models/Session";
import {TableViewWithoutPagination} from "../../components/TableViewWithoutPagination";
import {strings} from "../../localization/Localization";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Chart from "react-apexcharts";

interface SchedulingChartProps {
    isDashboard?: boolean;
}

export function SchedulingChart({ isDashboard = false }: SchedulingChartProps) {
    const [scheduledIntervals, setScheduledIntervals] = useState<ScheduledInterval[]>([]);
    const [numberOfNotDeadBots, setNumberOfNotDeadBots] = useState<number>(0);
    const [sessionRows, setSessionRows] = useState<any>([]);

    useEffect(() => {
        function getScheduledIntervals() {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            AccountGroupService.getScheduledIntervals(timeZone).then((data) => {
                setScheduledIntervals(data);
            });
        }

        function getConditionCount() {
            BotConditionService.botCondition().then((data) => {
                setNumberOfNotDeadBots(data?.alive! + data?.idle!);
            });
        }

        getScheduledIntervals();
        getConditionCount();
    }, []);

    function constructRowsAndShowTable(sessions: Session[]) {
        const rows = sessions.map((element) => ({
            id: element?.id,
            values: [
                { name: "sessionName", value: element?.name },
                { name: "accountGroupName", value: element?.accountGroup?.name },
                { name: "numberOfSchedules", value: element?.accountGroup?.schedules?.length },
                { name: "numberOfAccounts", value: element?.accountGroup?.accountIds?.length }
            ]
        }));
        setSessionRows(rows);
    }

    const data = scheduledIntervals.map((interval) => ({
        time: `${interval?.startSecondOfDay ? DateTimeUtils.convertSecToHours(interval.startSecondOfDay) : ''} - ${interval?.endSecondOfDay ? DateTimeUtils.convertSecToHours(interval.endSecondOfDay) : ''}`,
        scheduledAccounts: interval?.scheduledAccounts ?? 0
    }));

    const chartOptions: ApexCharts.ApexOptions = {
        chart: {
            type: 'bar',
            height: 350,
            events: {
                dataPointSelection: (event: any, chartContext: any, config: any) => {
                    const interval = scheduledIntervals[config.dataPointIndex];
                    if (interval) {
                        constructRowsAndShowTable(interval.sessions);
                    }
                }
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (val: number) => `${val}`,
            style: {
                colors: ['#000']
            }
        },
        xaxis: {
            categories: data.map(d => d.time),
            labels: {
                rotate: -45,
                formatter: (val: string, index: number) => {
                    const hour = parseInt(String(val).split(':')[0], 10);
                    return index === 0 || hour % 1 === 0 ? `${hour}:00` : '';
                }
            },
            tooltip: {
                enabled: true,
                formatter: (val: string, opts?: any) => {
                    const index = opts?.dataPointIndex ?? 0;
                    return `${data[index]?.time}`;
                }
            }
        },
        tooltip: {
            y: {
                formatter: (val: number) => `${val} accounts`
            },
            x: {
                formatter: (val: number) => `${val}`
            },
        },
        fill: {
            colors: data.map((d, index) => {
                const opacity = numberOfNotDeadBots === 0 ? 1 : scheduledIntervals[index].scheduledAccounts / numberOfNotDeadBots > 1 ? 1 : scheduledIntervals[index].scheduledAccounts / numberOfNotDeadBots;
                return `rgba(255, 0, 0, ${opacity})`;
            })
        }
    };

    const chartSeries = [{
        name: strings.scheduledAccounts,
        data: data.map(d => d.scheduledAccounts)
    }];

    return (
        <Box>
            <Box className={isDashboard ? "heatMapContainerDashboard" : "heatMapContainer"} p={2}>
                {isDashboard && (
                    <Typography textAlign="center" fontSize="25px" gutterBottom>
                        {strings.schedule}
                    </Typography>
                )}
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Chart options={chartOptions} series={chartSeries} type="bar" height={350} />
                </Paper>
            </Box>
            <Box mt={4} className={isDashboard ? "heatMapTableDashboard" : "heatMapTable"}>
                <TableViewWithoutPagination
                    columnNames={[strings.sessionName, strings.accountGroupName, strings.numberOfSchedules, strings.numberOfAccounts]}
                    rows={sessionRows}
                    emptyRowsMessage={strings.scheduledIntervalNoScenarios}
                    sx={{
                        '& .MuiTableCell-root': {
                            width: '420px',
                        }
                    }}
                />
            </Box>
        </Box>
    );
}
