import Chart from "react-apexcharts";
import * as React from "react";
import {ApexOptions} from "apexcharts";

interface Props {
    series: any;
    labels: any;
    type: 'radialBar';
    inverseColors: boolean;
}

export function ChartComponent({series, labels, type, inverseColors}: Props) {
    let chartHeight = 500;

    const determineGradientStops = (value: number, inverseColors: boolean) => {
        if (value <= 40) {
            return inverseColors
                ? [{ offset: 0, color: '#00FF00', opacity: 1 }, { offset: 100, color: '#00FF00', opacity: 1 }]
                : [{ offset: 0, color: '#FF0000', opacity: 1 }, { offset: 100, color: '#FF0000', opacity: 1 }];
        }
        if (value <= 60) {
            return inverseColors
                ? [{ offset: 0, color: '#FFFF00', opacity: 1 }, { offset: 100, color: '#00FF00', opacity: 1 }]
                : [{ offset: 0, color: '#FF0000', opacity: 1 }, { offset: 100, color: '#FFFF00', opacity: 1 }];
        }
        if (value <= 90) {
            return inverseColors
                ? [
                    { offset: 0, color: '#00FF00', opacity: 1 },
                    { offset: 40, color: '#FFFF00', opacity: 1 },
                    { offset: 55, color: '#FFFF00', opacity: 1 },
                    { offset: 100, color: '#FF0000', opacity: 1 },
                ]
                : [
                    { offset: 0, color: '#FF0000', opacity: 1 },
                    { offset: 40, color: '#FFFF00', opacity: 1 },
                    { offset: 55, color: '#FFFF00', opacity: 1 },
                    { offset: 100, color: '#00FF00', opacity: 1 },
                ];
        }
        return inverseColors
            ? [
                { offset: 0, color: '#00FF00', opacity: 1 },
                { offset: 45, color: '#FFFF00', opacity: 1 },
                { offset: 60, color: '#FFFF00', opacity: 1 },
                { offset: 100, color: '#FF0000', opacity: 1 },
            ]
            : [
                { offset: 0, color: '#FF0000', opacity: 1 },
                { offset: 45, color: '#FFFF00', opacity: 1 },
                { offset: 60, color: '#FFFF00', opacity: 1 },
                { offset: 100, color: '#00FF00', opacity: 1 },
            ];
    };

    const options: ApexOptions = {
        chart: {
            height: chartHeight,
            type: type,
            offsetY: -10,
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 135,
                dataLabels: {
                    name: {
                        fontSize: '16px',
                        offsetY: 150,
                        color: '#535f4f',
                    },
                    value: {
                        offsetY: 96,
                        fontSize: '22px',
                        formatter: function (val: number): string {
                            if (series[0] === -1) {
                                return "No data";
                            }
                            return val + "%";
                        },
                    },
                },
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                opacityFrom: 1,
                opacityTo: 1,
                colorStops: determineGradientStops(series[0], inverseColors),
            },
        },
        stroke: {
            dashArray: 4,
        },
        labels: labels,
    };

    return (<Chart options={options} series={series} height={chartHeight} type={type}/>);
}
