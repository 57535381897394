import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip
} from "@mui/material";
import {strings} from "../../localization/Localization";
import React, {useEffect, useState} from "react";
import {BotsDTO} from "../../models/BotsDTO";
import {BotsService} from "../../services/BotsService";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import '../../css/global.css';
import BlockIcon from '@mui/icons-material/Block';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import {useSearchParams} from "react-router-dom";
import {NumberParser} from "../../utils/NumberParser";
import {DashboardBotPreview} from "./DashboardBotPreview";
import {LightTooltip} from "../../components/LightTooltip";
import "../dashboard/TableCSS.css";
import {PaginationPlus} from "../../components/PaginationPlus";
import {BotStatuses} from "../../common/BotStatuses";
import {TvOff} from "@mui/icons-material";

export function DashboardBots() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [sentId, setSentId] = useState<number | undefined>(undefined);

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    const [bots, setBots] = useState<BotsDTO[]>([]);
    const [id] = useState<number | undefined>(undefined);
    const name = searchParams.get("name") ?? "";
    const lastLog = searchParams.get("lastLog") ?? "";
    const lastActionDate = searchParams.get("lastActionDate") ?? null;
    const dateCreated = searchParams.get("dateCreated") ?? null;
    const dateModified = searchParams.get("dateModified") ?? null;
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 4);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [totalElements, setTotalElements] = useState(0);

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    useEffect(() => {
        function getBots(page: number){
            BotsService.getAllBotsPaged(id, page, rowsPerPage,  name, lastLog,
                lastActionDate, dateCreated, dateModified,undefined).then((data) => {
                setBots(data.content);
                setTotalElements(data.totalElements);
            })
        }
        getBots(page);
    }, [id, page, rowsPerPage, name, lastLog, lastActionDate, dateCreated, dateModified]);

    return(
        sentId !== undefined ? <DashboardBotPreview
                sentId={sentId}
                onSentIdCleared={() => setSentId(undefined)}
            /> :
            <Box className={"tableCSS"}>
                <div className={"mt-3"}>
                    <div>Bots</div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 750 }} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{strings.botId}</TableCell>
                                    <TableCell>{strings.botName}</TableCell>
                                    <TableCell>{strings.botLastLog}</TableCell>
                                    <TableCell>{strings.botLastActionDate}</TableCell>
                                    <TableCell>{strings.dateCreated}</TableCell>
                                    <TableCell>{strings.dateModified}</TableCell>
                                    <TableCell>{strings.status}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bots?.map((bots) => (
                                    <TableRow
                                        key={bots?.id}
                                        hover={true}
                                        className="cursor-pointer"
                                        onClick={() => setSentId(bots?.id)}
                                    >
                                        <LightTooltip title={bots?.id?.toString()}><TableCell width={"10%"} className="truncate-table-cell">{bots?.id}</TableCell></LightTooltip>
                                        <LightTooltip title={bots.name}><TableCell width={"15%"} className="truncate-table-cell">{bots.name}</TableCell></LightTooltip>
                                        <LightTooltip title={bots.lastLog}><TableCell width={"20%"} className="truncate-table-cell">{bots.lastLog}</TableCell></LightTooltip>
                                        <LightTooltip title={bots.lastActionDate}><TableCell width={"20%"} className="truncate-table-cell">{DateTimeUtils.formatDate(bots.lastActionDate)}</TableCell></LightTooltip>
                                        <LightTooltip title={bots.dateCreated}><TableCell width={"20%"} className="truncate-table-cell">{DateTimeUtils.formatDate(bots.dateCreated)}</TableCell></LightTooltip>
                                        <LightTooltip title={bots.dateModified}><TableCell width={"20%"} className="truncate-table-cell">{DateTimeUtils.formatDate(bots.dateModified)}</TableCell></LightTooltip>
                                        {bots.status === BotStatuses.INACTIVE ? <Tooltip title={"Inactive"}><TableCell width={"10%"}><BlockIcon color={'error'}/></TableCell></Tooltip> : null}
                                        {bots.status === BotStatuses.DIED ? <Tooltip title={"Died"}><TableCell width={"20%"}><TvOff color={'error'}/></TableCell></Tooltip> : null}
                                        {bots.status === BotStatuses.IDLE ? <Tooltip title={"Idle"}><TableCell width={"20%"}><AccessAlarmIcon color={'warning'}/></TableCell></Tooltip> : null}
                                        {bots.status === BotStatuses.ACTIVE ? <Tooltip title={"Active"}><TableCell width={"20%"}><LiveTvIcon color={"success"}/></TableCell></Tooltip> : null}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                    <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                    <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={4}
                    page={page}
                    onPageChange={handleChangePage}
                />
                </Grid>
            </Box>
    )
}
